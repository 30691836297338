.button {
  --padding-xsmall: 0.625em;
  --padding-small: 0.875em;
  --padding-base: 1em;
  --padding-lg: 1.125em;
  --icon-radius: 18px;

  box-sizing: border-box;
  border-radius: calc(var(--icon-radius) + 2px);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0;
  gap: 1rem;
}

@media screen and (min-width: 800px) {
  .button {
    --icon-radius: 14px;
  }
}

/* Light theme base */
.button--primary {
  --background-color: var(--blue);
  --color: var(--off-white--lighter);
  --mix-percent: 10%;

  background-color: var(--background-color);
  border: 2px solid transparent;
  color: var(--color);
}

.button--primary:hover {
  background-color: color-mix(in srgb, var(--background-color) var(--mix-percent), transparent);
  color: var(--background-color);
}

.button--primary:focus {
  outline: none; /* stylelint-disable-line */
  background-color: var(--background-color);
  color: var(--color);
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--background-color) 50%, transparent);
}

.button--primary:active {
  outline: none; /* stylelint-disable-line */
  background-color: color-mix(in srgb, var(--background-color), var(--charcoal--darker) 5%);
}

.button--primary:disabled {
  background-color: color-mix(in srgb, var(--charcoal--darker) 5%, transparent);
  color: color-mix(in srgb, var(--charcoal--darker) 20%, transparent);
  pointer-events: none;
}

/* Blue theme base */
.button--primary.button--blue {
  --background-color: var(--off-white--lighter);
  --color: var(--blue);
  --mix-percent: 15%;
}

/* Dark theme base */
.button--primary.button--dark {
  --background-color: var(--blue--lighter);
  --color: var(--charcoal--darker);
  --mix-percent: 15%;
}

.button--primary.button--dark:disabled,
.button--primary.button--blue:disabled {
  background-color: color-mix(in srgb, var(--off-white--lighter) 5%, transparent);
  color: color-mix(in srgb, var(--off-white--lighter) 25%, transparent);
}

/* Light secondary theme base */
.button--secondary {
  --color: var(--blue);
  --secondary-color: var(--off-white--lighter);
  --mix-percent: 10%;
  --background-color: color-mix(in srgb, var(--blue) var(--mix-percent), transparent);

  background-color: var(--background-color);
  border: 2px solid transparent;
  color: var(--color);
}

.button--secondary:hover {
  background-color: var(--color);
  color: var(--secondary-color);
}

.button--secondary:focus {
  outline: none; /* stylelint-disable-line */
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--color) 50%, transparent);
}

.button--secondary:active {
  outline: none; /* stylelint-disable-line */
  box-shadow: unset;
  background-color: color-mix(in srgb, var(--background-color), var(--charcoal--darker) 5%);
  color: var(--color);
}

.button--secondary:disabled {
  background-color: color-mix(in srgb, var(--charcoal--darker) 5%, transparent);
  color: color-mix(in srgb, var(--charcoal--darker) 20%, transparent);
  pointer-events: none;
}

/* Dark secondary theme base */
.button--secondary.button--dark {
  --color: var(--blue--lighter);
  --secondary-color: var(--charcoal--darker);
  --background-color: color-mix(in srgb, var(--blue--lighter) var(--mix-percent), transparent);
}

/* Blue secondary theme base */
.button--secondary.button--blue {
  --color: var(--off-white--lighter);
  --secondary-color: var(--blue);
  --background-color: color-mix(in srgb, var(--off-white--lighter) var(--mix-percent), transparent);
}

.button--secondary.button--dark:disabled,
.button--secondary.button--blue:disabled {
  --color: var(--off-white--lighter);

  background-color: color-mix(in srgb, var(--off-white--lighter) 5%, transparent);
  color: color-mix(in srgb, var(--off-white--lighter) 25%, transparent);
}

/* Base icon styling */
.button__icon {
  display: inline-flex;
  height: 100%;
  padding: var(--padding-small) var(--padding-lg);
  border-radius: var(--icon-radius);
}

@media screen and (min-width: 800px) {
  .button__icon {
    padding: var(--padding-xsmall) var(--padding-small);
  }
}

.button__icon .icon {
  height: 1em;
  width: 1em;
}

/* Base label styling */
.button__label {
  padding: var(--padding-base) 2rem;
}

@media screen and (min-width: 800px) {
  .button__label {
    padding: var(--padding-xsmall) 2rem;
  }
}

.button__icon + .button__label {
  padding: 0 2rem 0 0;
}

/* Animation */
.button--cta:hover .button__icon {
  overflow: hidden;
  color: var(--background-color);
}

.button--cta:hover .button__icon .icon {
  animation: arrowMove 2s cubic-bezier(0.6, 0, 0, 1) infinite;
}

/* Themed icon styling */
.button--secondary:hover .button__icon {
  background-color: var(--color);
  color: var(--secondary-color);
}

.button--secondary:active .button__icon {
  background-color: color-mix(in srgb, var(--background-color), var(--charcoal--darker) 5%);
  color: var(--color);
}

.button--primary .button__icon:has(+ .button__label) {
  background-color: color-mix(in srgb, var(--color) 10%, transparent);
}

.button--secondary .button__icon:has(+ .button__label) {
  background-color: color-mix(in srgb, var(--color) 10%, transparent);
}

.button--primary.button--dark:disabled .button__icon {
  background-color: color-mix(in srgb, var(--off-white--lighter) 5%, transparent);
}

.button--primary.button--blue:disabled .button__icon {
  background-color: color-mix(in srgb, var(--charcoal--darker) 5%, transparent);
}

.button--secondary.button--dark:disabled .button__icon,
.button--secondary.button--blue:disabled .button__icon {
  background-color: color-mix(in srgb, var(--color) 5%, transparent);
}

.button--secondary:focus .button__icon:has(+ .button__label) {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--color) 50%, transparent);
}

.button--primary.button--dark .button__icon:has(+ .button__label) {
  background-color: color-mix(in srgb, var(--off-white--lighter) 25%, transparent);
}

.button--primary:disabled .button__icon:has(+ .button__label) {
  background-color: color-mix(in srgb, var(--off-white--lighter) 15%, transparent);
}

.button--primary:hover .button__icon:has(+ .button__label) {
  background-color: var(--background-color);
  color: var(--color);
}

.button--primary:active .button__icon:has(+ .button__label) {
  background-color: color-mix(in srgb, var(--color) 10%, transparent);
}

.button--primary.button--dark:hover .button__icon:has(+ .button__label) {
  background-color: var(--blue--lighter);
}

.button--primary.button--dark:active .button__icon:has(+ .button__label) {
  background-color: color-mix(in srgb, var(--off-white--lighter) 25%, transparent);
}

.button--secondary:has(.button__icon + .button__label) {
  background-color: transparent;
}

.button--secondary:has(.button__icon + .button__label):hover,
.button--secondary:has(.button__icon + .button__label):hover .button__icon {
  color: var(--color);
}

.button--secondary:has(.button__icon + .button__label):focus {
  outline: none; /* stylelint-disable-line */
  box-shadow: unset;
}

@keyframes arrowMove {
  0% {
    transform: translateX(0%);
  }

  35% {
    transform: translateX(200%);
  }

  35.001% {
    transform: translateX(-200%);
  }

  45% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(0%);
  }
}
