:root {
  /* Background color scale */
  --color-background-100: rgba(242, 242, 242, 1);
  --color-background-60: rgba(242, 242, 242, 0.65);

  /* Error color scale */
  --color-error-dark: #9d0303;
  --color-error-light: #ffbfda;

  /* Black color scale */
  --color-black-100: #000209;
  --color-black-80: rgba(0, 2, 9, 0.8);
  --color-black-60: rgba(0, 2, 9, 0.6);
  --color-black-40: rgba(0, 2, 9, 0.4);
  --color-black-20: rgba(0, 2, 9, 0.2);
  --color-black-10: rgba(0, 2, 9, 0.1);
  --color-black-5: rgba(0, 2, 9, 0.05);

  /* White color scale */
  --color-white-100: #ffffff;
  --color-white-80: rgba(255, 255, 255, 0.85);
  --color-white-60: rgba(255, 255, 255, 0.65);
  --color-white-40: rgba(255, 255, 255, 0.45);
  --color-white-20: rgba(255, 255, 255, 0.25);
  --color-white-10: rgba(255, 255, 255, 0.15);
  --color-white-5: rgba(255, 255, 255, 0.1);

  /* Blue color scale */
  --color-blue-100: #2663da;
  --color-blue-50: rgba(38, 99, 218, 0.5);
  --color-blue-10: rgba(38, 99, 218, 0.1);

  /* Light Blue color scale */
  --color-light-blue-100: rgba(165, 195, 242, 1);
  --color-light-blue-50: rgba(165, 195, 242, 0.5);
  --color-light-blue-10: rgba(165, 195, 242, 0.15);
  --blue: rgba(38, 99, 218, 1);
  --blue--lighter: rgba(165, 195, 242, 1);
  --blue--darker: rgba(61, 106, 173, 1);
  --charcoal: rgba(47, 48, 51, 1);
  --charcoal--lighter: rgba(87, 88, 93, 1);
  --charcoal--darker: rgba(0, 0, 0, 1);
  --off-white: rgba(242, 242, 242, 1);
  --off-white--lighter: rgba(255, 255, 255, 1);
  --off-white--darker: color-mix(in srgb, var(--off-white) 95%, #000);
  --supporting--disabled: rgba(0, 0, 0, 0.25);
  --supporting--disabled-stronger: rgba(0, 0, 0, 0.4);
  --supporting--disabled-inverted: rgba(186, 186, 186, 0.5);
  --error--dark: rgba(157, 3, 3, 1);
  --error--light: rgba(255, 191, 218, 1);
}
