.cta {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: var(--blue);
}

.cta__inner {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 800px) {
  .cta__inner {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.cta__content {
  grid-column: 1 / -1;
}

@media screen and (min-width: 800px) {
  .cta__content {
    grid-column: 1 / -3;
  }
}

@media screen and (min-width: 1280px) {
  .cta__content {
    grid-column: 1 / -7;
  }
}

.cta__title {
  color: var(--off-white);
  margin: 0 0 1em;
}

.cta__copy {
  color: var(--off-white);
}

.cta__cta {
  margin-top: 2em;
}
