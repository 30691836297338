.video-overlay {
  cursor: pointer;
  position: relative;
}

.video-overlay::before {
  background-color: var(--blue);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M19.633 12.316v-.586L4.503 3 4 3.293v17.46l.504.294 15.129-8.73Z'/%3E%3C/svg%3E");
  background-position: center;
  background-size: 1.5rem;
  background-repeat: no-repeat;
  border-radius: 24px;
  box-shadow: 0 0 75px rgba(54, 54, 54, 0.35);
  content: "";
  display: block;
  height: 4rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: box-shadow 0.1s ease-in;
  width: 5rem;
  z-index: 1;
}

.video-overlay:hover::before {
  box-shadow: 0 0 75px rgba(54, 54, 54, 1);
}

.video-overlay::after {
  content: "";
  backdrop-filter: grayscale(100%);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.video-overlay__overlay {
  animation-duration: 0.4s;
  animation-name: video-overlay__fade-in;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(15px);
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.video-overlay__overlay--active {
  display: block;
}

.video-overlay__content {
  animation-duration: 0.4s;
  animation-name: video-overlay__scale-in;
  background-color: black;
  height: 100%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  max-height: 400px;
  max-width: 500px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.video-overlay__iframe,
.video-overlay__video {
  border: none;
  display: block;
  height: 100%;
  width: 100%;
}

.video-overlay__wrapper {
  height: 100%;
  width: 100%;
}

.video-overlay__close {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 100 100'%3E%3Cpath fill='%23FFF' d='m79.167 26.709-5.875-5.875L50 44.124 26.708 20.834l-5.875 5.875L44.125 50 20.833 73.292l5.875 5.875L50 55.875l23.292 23.292 5.875-5.875L55.875 50 79.167 26.71Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  height: 2rem;
  padding: 0;
  position: absolute;
  right: 0;
  top: -2.5rem;
  width: 2rem;
}

.video-overlay__close:hover,
.video-overlay__close:focus {
  opacity: 0.4;
}

@media screen and (min-width: 768px) {
  .video-overlay__content {
    max-height: 500px;
    max-width: 600px;
  }

  .video-overlay__close-btn {
    background-size: 23px;
    height: 23px;
    right: 16px;
    top: 20px;
    width: 23px;
  }
}

@media screen and (min-width: 1024px) {
  .video-overlay__content {
    max-height: 600px;
    max-width: 800px;
  }
}

@keyframes video-overlay__scale-in {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes video-overlay__fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
