.quote-block {
  background-color: var(--off-white);
}

.quote-block__inner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: var(--max-width);
  margin: auto;
  padding: 2rem var(--grid-horizontal-padding);
}

@media screen and (min-width: 800px) {
  .quote-block__inner {
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    padding: 4rem var(--grid-horizontal-padding);
  }
}

.quote-block__icon-wrapper {
  flex: 0 1 50%;
}

.quote-block__quote-icon {
  width: 4rem;
  fill: color-mix(in srgb, var(--charcoal--darker) 20%, transparent);
}

@media screen and (min-width: 800px) {
  .quote-block__quote-icon {
    width: 8rem;
  }
}

.quote-block__quote-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 0 1 50%;
}

.quote-block__quote {
  font-style: italic;
  line-height: 1.3;
  color: var(--charchoal--darker);
  margin: 0;
}

.quote-block__author {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .quote-block__author {
    align-items: center;
  }
}

.quote-block__author-image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.quote-block__author-info {
  flex-grow: 1;
}

.quote-block__author-name {
  display: block;
  color: var(--charchoal--darker);
}

.quote-block__author-title {
  display: block;
  color: color-mix(in srgb, var(--charcoal--darker) 60%, transparent);
  margin-top: 0.5rem;
}

@media screen and (min-width: 800px) {
  .quote-block__title {
    margin-top: 0.75rem;
  }
}

.quote-block__logo {
  flex: 0 0 auto;
}

.quote-block__logo svg,
.quote-block__logo img {
  object-fit: contain;
  height: 48px;
  width: unset;
}
