.use-case__inner {
  max-width: var(--max-width);
  margin: auto;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.use-case__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (min-width: 1280px) {
  .use-case__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

.use-case__column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.use-case__column--spacing-small {
  gap: 1.5rem;
}

.use-case__tags {
  grid-column: 1;
}

.use-case__headline {
  margin: 0;
}

.use-case__subheadline {
  margin: 0;
  color: color-mix(in srgb, var(--charcoal--darker) 60%, transparent);
}

.use-case__background p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.use-case__technologies-list {
  list-style: none;
  padding: 0;
}

.use-case__technology {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 0;
  min-height: 60px;
  border-top: 1px solid color-mix(in srgb, var(--charcoal--darker) 10%, transparent);
}

.use-case__technology:first-child {
  border-top: none;
}

.use-case__technology img,
.use-case__technology svg {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
}

.use-case__technology-label {
  flex-grow: 1;
}
