.icon {
  width: 100%;
  height: 100%;
}

.icon--light {
  color: var(--blue);
}

.icon--light:hover {
  color: var(--blue--lighter);
}

.icon--light:focus {
  color: var(--off-white);
  background-color: var(--blue);
  border-radius: 4px;
}

.icon--light:active {
  color: var(--blue--darker);
  background-color: unset;
}

.icon--light:disabled,
.icon--disabled {
  color: var(--supporting--disabled);
  cursor: default;
}

.icon--dark {
  color: var(--blue--lighter);
}

.icon--dark:hover {
  color: var(--blue);
}

.icon--dark:focus {
  color: var(--charcoal);
  background-color: var(--blue--lighter);
}

.icon--dark:active {
  color: var(--blue--darker);
  background-color: unset;
}

.icon--dark:disabled,
.icon--dark.icon--disabled {
  color: var(--supporting--disabled-inverted);
}

.icon--blue {
  color: var(--charcoal--darker);
}

.icon--blue:hover {
  color: var(--off-white);
}

.icon--blue:focus {
  color: var(--blue--lighter);
  background-color: var(--charcoal--darker);
}

.icon--blue:active {
  color: var(--charcoal);
  background-color: unset;
}

.icon--blue:disabled,
.icon--blue.icon--disabled {
  color: var(--supporting--disabled);
}

.icon--footer {
  color: var(--off-white);
}

.icon--footer:hover {
  color: var(--blue--lighter);
}

.icon--footer:focus {
  color: var(--charcoal);
  background-color: var(--blue--lighter);
}

.icon--footer:active {
  color: var(--blue--darker);
  background-color: unset;
}

.icon--footer:disabled,
.icon--footer.icon--disabled {
  color: var(--supporting--disabled-inverted);
}
