.styleguide-header {
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.styleguide-header h1 {
  grid-column: 1 / -1;
}

.styleguide-block > h2 {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #000;
  padding-top: 1rem;
}

.styleguide-block > h2 > span {
  background-color: #fff;
  padding: 0.25rem 0.5rem;
}
