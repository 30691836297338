.media {
  position: relative;
  isolation: isolate;
}

.media img {
  display: block;
  width: 100%;
}

.media__video-wrapper {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
}

.media__video-wrapper--square {
  aspect-ratio: 1 / 1;
}

/* This ensures the video takes up the full width of the wrapper while maintaining aspect ratio */
.media__video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  margin: 0;
  padding: 0;
  border-top-left-radius: var(--corner-radius-m);
  border-bottom-right-radius: var(--corner-radius-m);
}

@media (min-width: 800px) {
  .media__video {
    border-top-left-radius: var(--corner-radius-l);
    border-bottom-right-radius: var(--corner-radius-l);
  }
}

@media (min-width: 1200px) {
  .media__video {
    border-top-left-radius: var(--corner-radius-xl);
    border-bottom-right-radius: var(--corner-radius-xl);
  }
}

.media__video-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: var(--corner-radius-m);
}

@media (min-width: 800px) {
  .media__video-embed {
    border-radius: var(--corner-radius-l);
  }
}

@media (min-width: 1200px) {
  .media__video-embed {
    border-radius: var(--corner-radius-xl);
  }
}

.media__video-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
}

.media__video-wrapper--playing::before {
  opacity: 0;
  pointer-events: none;
}

.media__video-wrapper--autoplay-only::before {
  opacity: 0;
}

.media__video-sound-btn,
.media__video-play-btn {
  position: absolute;
  z-index: 3;
  cursor: pointer;
}

.media__video-sound-btn {
  top: 1rem;
  left: 1rem;
}

.media__video-play-btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease;
}

.media__video-wrapper--playing .media__video-play-btn {
  opacity: 0;
}

.media__video-controls-wrapper {
  display: none;
  position: absolute;
  bottom: 0.75rem;
  left: 0.75rem;
  right: 0.75rem;
  transition: opacity 0.2s ease-in-out;
  z-index: 5;
  gap: 0.75rem;
}

@media screen and (max-width: 767px) {
  .media__video-controls-wrapper {
    height: 44px;
  }
}

.media__video-controls {
  display: flex;
  padding: 10px;
  background: var(--color-black-80);
  border-radius: var(--corner-radius-xl);
  width: 100%;
}

@media (max-width: 767px) {
  .media__video-controls {
    padding: 5px;
  }
}

.media__video-controls button {
  border-radius: var(--corner-radius-m) !important;
}

@media (min-width: 768px) {
  .media__video-wrapper .media__video-controls-wrapper {
    display: none;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .media__video-wrapper:hover .media__video-controls-wrapper {
    display: flex;
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .media__video-wrapper .media__video-controls-wrapper {
    display: flex;
    opacity: 1;
  }
}

.media__video-controls-wrapper button {
  min-width: 64px;
  border-radius: var(--corner-radius-xl);
}

.media__video-controls-wrapper .button__icon {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.media__video-controls button,
.media__video-controls input[type="range"] {
  margin-right: 10px;
}

.media__video-controls button:last-child {
  margin-right: 0;
}

.media--molten img {
  border-top-left-radius: var(--corner-radius-m);
  border-bottom-right-radius: var(--corner-radius-m);
}

@media (min-width: 800px) {
  .media--molten img {
    border-top-left-radius: var(--corner-radius-l);
    border-bottom-right-radius: var(--corner-radius-l);
  }
}

@media (min-width: 1200px) {
  .media--rounded img {
    border-radius: var(--corner-radius-xl);
  }
}

.media--rounded img {
  border-radius: var(--corner-radius-m);
}

@media (min-width: 800px) {
  .media--rounded img {
    border-radius: var(--corner-radius-l);
  }
}

@media (min-width: 1200px) {
  .media--rounded img {
    border-radius: var(--corner-radius-xl);
  }
}

.media.media--volumetric-shadow img {
  box-shadow:
    0 0 64px rgba(0, 0, 0, 0.12),
    inset -1px -1px 0 rgba(0, 0, 0, 0.1),
    inset 1px 1px 0 rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(32px);
}

.media.media--drop-shadow img {
  filter: drop-shadow(0 0 48px rgba(0, 0, 0, 0.04));
}

.media__liquid-corner {
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 2;
  pointer-events: none;
  user-select: none;
}

@media screen and (min-width: 800px) {
  .media__liquid-corner {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .media__liquid-corner {
    width: 48px;
    height: 48px;
  }
}

.media__top-right {
  top: -0.02em;
  right: -0.02em;
}

.media__bottom-left {
  bottom: -0.02em;
  left: -0.02em;
}

.media__video-progress-bar {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 40px;
  background-color: #1e1e1e;
  border-radius: var(--corner-radius-m);
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 767px) {
  .media__video-progress-bar {
    height: 34px;
  }
}

.media__video-progress-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1e1e1e;
  border-radius: 10px;
}

.media__video-progress-bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--blue);
  border-radius: 10px 0 0 10px;
  width: var(--progress, 0%);
  transition: width 0.2s ease-in-out;
}

.media__video-progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
}

.media__video-progress-bar::-moz-range-thumb {
  width: 0;
  height: 0;
}

.media__video-subtitles-btn {
  display: none; /* Hide until action is defined */
}
