.teaser-item {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  height: 100%;
  max-height: 108px;
  overflow: hidden;
  border-radius: var(--corner-radius-l);
  background-color: var(--color-white-60);
}

.teaser-item__media {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 108px;
  background-color: var(--color-black-10);
}

.teaser-item__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem;
  gap: 0.5rem;
}

.teaser-item__headline {
  margin: 0;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.teaser-item__details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: var(--color-black-60);
}

.teaser-item__fallback-image {
  width: 32px;
  height: 32px;
}
