.media-reel {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: var(--off-white);
}

.media-reel__inner {
  max-width: var(--max-width);
  margin: auto;
  padding: 1.5rem 0;
}

@media screen and (min-width: 800px) {
  .media-reel__inner {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.media-reel__reel {
  scroll-snap-type: x mandatory;
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  width: 100vw;
  transform: translateX(calc(var(--grid-horizontal-padding) * -1));
}

.media-reel__reel::-webkit-scrollbar-track,
.media-reel__reel::-webkit-scrollbar,
.media-reel__reel::-webkit-scrollbar-thumb {
  display: none;
}

@media screen and (min-width: 1280px) {
  .media-reel__reel {
    width: auto;
    transform: unset;
    overflow-x: initial;
  }
}

.media-reel__item {
  min-width: calc((100vw - 2 * var(--grid-horizontal-padding)) * 0.9);
  padding-left: var(--grid-horizontal-padding);
  padding-right: 20px;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media screen and (min-width: 1280px) {
  .media-reel__item {
    min-width: unset;
    flex: 1 0 100%;
    padding-left: 0;
    padding-right: 2rem;
    transform: translateX(calc(var(--active-index, 0) * -100%));
    transition: transform 0.3s ease;
  }

  .media-reel__item > .media-reel__details {
    display: none;
  }
}

.media-reel__content {
  display: none;
}

@media screen and (min-width: 1280px) {
  .media-reel__content {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
}

.media-reel__details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
}

@media screen and (min-width: 1280px) {
  .media-reel__content .media-reel__details {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
    grid-column: 1;
    grid-row: 1;
  }

  .media-reel__content .media-reel__details.active {
    opacity: 1;
    visibility: visible;
  }
}

.media-reel__headline-icon svg,
.media-reel__headline-icon img {
  height: 2.5rem;
  width: 2.5rem;
}

.media-reel__headline {
  display: flex;
  gap: 1rem;
  margin: 0;
}

.media-reel__arrows {
  display: none;
  cursor: pointer;
  justify-self: flex-end;
  align-self: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 1280px) {
  .media-reel__arrows {
    display: block;
    gap: 0.5rem;
    grid-column: 2;
    grid-row: 1;
  }
}

.media-reel__details p {
  margin: 0;
}
