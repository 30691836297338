.spacer {
  position: relative;
  padding: 1.5rem 0;
}

@media screen and (min-width: 800px) {
  .spacer {
    padding: 2rem 0;
  }
}

.spacer__inner {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  margin: auto;
}

.spacer__inner--none {
  border-top: none;
}

.spacer__inner--light {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.spacer__inner--medium {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

.spacer__inner--heavy {
  border-top: 4px solid rgba(0, 0, 0, 0.2);
}
