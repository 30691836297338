.basic-two-column {
  position: relative;
}

.basic-two-column__inner {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  margin: auto;
  padding: 1.5rem 0;
}

@media screen and (min-width: 800px) {
  .basic-two-column__inner {
    padding: 2rem 0;
  }
}

.basic-two-column--light {
  background-color: var(--off-white);
  color: var(--charcoal--darker);
}

.basic-two-column--light-alt {
  background-color: var(--off-white--darker);
  color: var(--charcoal--darker);
}

.basic-two-column--blue {
  background-color: var(--blue--lighter);
  color: var(--charcoal--darker);
}

.basic-two-column--dark {
  background-color: var(--charcoal);
  color: var(--off-white);
}

.basic-two-column__content {
  align-content: start;
  display: grid;
  position: relative;
  grid-column: 1 / -1;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

@media screen and (min-width: 800px) {
  .basic-two-column__content {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1280px) {
  .basic-two-column__content {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.basic-two-column__content:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .basic-two-column__content--reverse {
    direction: rtl;
  }
}

.basic-two-column__text {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (min-width: 800px) {
  .basic-two-column__text {
    grid-column: 5 / -1;
  }

  .basic-two-column__text--wide {
    grid-column: 3 / -1;
  }
}

@media screen and (min-width: 1280px) {
  .basic-two-column__text {
    grid-column: 7 / -1;
  }

  .basic-two-column__text--wide {
    grid-column: 5 / -1;
  }
}

.basic-two-column__headline {
  margin: 0;
}

.basic-two-column__text-body p:first-child {
  margin-top: 0.725rem;
}

@media screen and (min-width: 800px) {
  .basic-two-column__text-body p:first-child {
    margin-top: 1.5rem;
  }
}

.basic-two-column__text-body p:last-child {
  margin-bottom: 0;
}

.basic-two-column__text-body a {
  color: var(--blue);
}

.basic-two-column__text-body a:hover {
  color: var(--blue--lighter);
}

.basic-two-column--dark .basic-two-column__text-body a {
  color: var(--blue--lighter);
}

.basic-two-column--dark .basic-two-column__text-body a:hover {
  color: var(--blue);
}

.basic-two-column--blue .basic-two-column__text-body a:hover {
  color: var(--off-white) !important;
}

.basic-two-column__media {
  position: relative;
  width: 100%;
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 800px) {
  .basic-two-column__media {
    grid-column: 1 / 5;
    margin-bottom: unset;
  }
}

@media screen and (min-width: 1280px) {
  .basic-two-column__media {
    grid-column: 1 / 7;
  }
}

.basic-two-column__content--reverse .basic-two-column__media {
  margin-top: 1.5rem;
  margin-bottom: 0;
  order: 1;
}

@media screen and (min-width: 800px) {
  .basic-two-column__content--reverse .basic-two-column__text {
    direction: ltr;
  }
}

@media screen and (min-width: 800px) {
  .basic-two-column__content--reverse .basic-two-column__media {
    margin-top: unset;
    order: unset;
  }
}

.basic-two-column__form {
  position: relative;
  width: 100%;
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;
  direction: ltr;
}

.basic-two-column__content--reverse .basic-two-column__form {
  margin-top: 1.5rem;
  margin-bottom: 0;
  order: 1;
}

@media screen and (min-width: 800px) {
  .basic-two-column__content--reverse .basic-two-column__form {
    grid-row: 1;
    margin-top: 0;
  }
}

@media screen and (min-width: 800px) {
  .basic-two-column__form {
    grid-column: 1 / 5;
    margin-bottom: unset;
  }
}

@media screen and (min-width: 1280px) {
  .basic-two-column__form {
    grid-column: 1 / 7;
  }
}
