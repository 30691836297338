.post-collection {
  padding: 1.5rem var(--grid-horizontal-padding);
}

@media screen and (min-width: 800px) {
  .post-collection {
    padding: 2rem var(--grid-horizontal-padding);
  }
}

.post-collection--light {
  background-color: var(--off-white);
  color: var(--charcoal--darker);
}

.post-collection--light-alt {
  background-color: var(--off-white--darker);
  color: var(--charcoal--darker);
}

.post-collection--blue {
  background-color: var(--blue--lighter);
  color: var(--charcoal--darker);
}

.post-collection--dark {
  background-color: var(--charcoal);
  color: var(--off-white);
}

.post-collection__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
}
