.list {
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: var(--grid-columns);
  margin: 0;
  max-width: var(--max-width);
}

.list:last-child {
  margin-bottom: 0;
}

.list__inner {
  grid-column: span 6;
}

@media screen and (min-width: 800px) {
  .list__inner {
    grid-column: 2 / -2;
  }
}

@media screen and (min-width: 1180px) {
  .list__inner {
    grid-column: 3 / -3;
  }
}

@media screen and (min-width: 1280px) {
  .list__inner {
    grid-column: 4 / -4;
  }
}

.list ul {
  font-size: var(--text-preset-paragraph__font-size);
  line-height: var(--text-preset-paragraph__line-height);
  margin: 0;
}

@media screen and (min-width: 800px) {
  .list ul {
    margin-left: 0;
  }
}

.list ul:last-child {
  margin-bottom: 0;
}

.list a {
  text-decoration: underline;
}

.list ol {
  list-style-type: none;
  counter-reset: item;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  padding: 0;
}

.list ol li {
  counter-increment: item;
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
}

.list ol li::before {
  content: counter(item);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}
