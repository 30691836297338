.footer {
  --spacing-xsmall: 0.5rem;
  --spacing-small: 1rem;
  --spacing-medium: 1.5rem;
  --spacing-large: 2rem;
  --spacing-xlarge: 2.5rem;

  box-sizing: border-box;
  background-color: var(--charcoal);
  color: var(--off-white--lighter);
  padding: var(--spacing-xlarge) var(--grid-horizontal-padding);
  position: relative;
}

.footer::before,
.footer::after {
  content: "";
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--charcoal);
  position: absolute;
  bottom: 100%;
}

.footer::before {
  clip-path: path("M0 0v24h24C10.746 24 0 13.254 0 0Z");
  left: 0;
}

.footer::after {
  clip-path: path("M0 24h24V0c0 13.254-10.746 24-24 24Z");
  right: 0;
}

.footer__container {
  display: grid;
  grid-template-areas:
    "contact" "navigation"
    "address" "copyright";
  max-width: var(--max-width);
  margin: 0 auto;
  column-gap: 3rem;
}

@media screen and (min-width: 1200px) {
  .footer__container {
    grid-template-columns: 1fr 1fr;
  }
}

.footer__section {
  flex: 0 0 50%;
}

.footer__section--contact {
  color: color-mix(in srgb, var(--off-white--lighter) 60%, transparent);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  margin-bottom: var(--spacing-large);
}

@media screen and (min-width: 1200px) {
  .footer__section--links {
    display: flex;
    gap: var(--spacing-medium);
  }
}

.footer__newsletter-label {
  display: block;
}

.footer__input-wrapper {
  display: flex;
  gap: var(--spacing-xsmall);
}

.footer__form-feedback {
  display: none;
}

.footer__form-feedback--visible {
  display: block;
}

.footer__input {
  padding: var(--spacing-small);
  background-color: color-mix(in srgb, var(--off-white--lighter) 10%, transparent);
  color: color-mix(in srgb, var(--off-white--lighter) 50%, transparent);
  border: none;
  border-radius: 1rem;
  flex-grow: 1;
}

.footer__button-link {
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-small) var(--spacing-medium);
  background-color: color-mix(in srgb, var(--blue--lighter) 10%, transparent);
  color: var(--blue--lighter);
  border: none;
  border-radius: 1.25rem;
  cursor: pointer;
}

.footer__button-link:disabled {
  pointer-events: none;
  background-color: var(--charcoal--lighter);
  color: var(--charcoal);
}

.footer__button-link:hover,
.footer__button-link:focus {
  background-color: color-mix(in srgb, var(--blue--lighter) 20%, transparent);
}

.footer__button-icon {
  display: inline-flex;
  align-items: center;
  height: 1rem;
  width: 1rem;
}

.footer__social-label {
  display: block;
}

.footer__social-items {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: var(--spacing-xsmall);
}

.footer__link-column {
  flex: 1 0 auto;
}

.footer__links-category {
  border-top: 1px solid color-mix(in srgb, var(--off-white--lighter) 20%, transparent);
  padding: var(--spacing-large) 0;
}

@media screen and (min-width: 1200px) {
  .footer__links-category {
    padding: var(--spacing-medium) 0;
  }

  .footer__links-category:first-child {
    border-top: none;
    padding-top: 0;
  }
}

.footer__links-title {
  color: color-mix(in srgb, var(--off-white--lighter) 60%, transparent);
  margin: 0;
}

.footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__link-item {
  padding: var(--spacing-small) 0;
}

@media screen and (min-width: 1200px) {
  .footer__link-item {
    padding: var(--spacing-xsmall) 0;
  }
}

.footer__link {
  display: flex;
  align-items: center;
  gap: var(--spacing-xsmall);
}

.footer__link--highlighted {
  color: var(--blue--lighter);
}

.footer__link-icon {
  display: inline-flex;
  align-items: center;
  color: color-mix(in srgb, var(--off-white--lighter) 40%, transparent);
  height: 1rem;
  width: 1rem;
}

.footer__address {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-style: normal;
  border-top: 1px solid color-mix(in srgb, var(--off-white--lighter) 20%, transparent);
  padding: var(--spacing-large) 0;
}

@media screen and (min-width: 1200px) {
  .footer__address {
    padding-bottom: 0;
  }
}

.footer__address p {
  margin: 0;
}

.footer__copyright {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: color-mix(in srgb, var(--off-white--lighter) 60%, transparent);
  border-top: 1px solid color-mix(in srgb, var(--off-white--lighter) 20%, transparent);
  padding-top: var(--spacing-large);
}

.footer__copyright p {
  margin: 0;
}
