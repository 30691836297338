.quote {
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: var(--grid-columns);
  margin: 0;
  max-width: var(--max-width);
}

.quote__inner {
  grid-column: span 6;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

@media screen and (min-width: 800px) {
  .quote__inner {
    grid-column: 2 / -2;
  }
}

@media screen and (min-width: 1180px) {
  .quote__inner {
    grid-column: 2 / -3;
  }
}

@media screen and (min-width: 1280px) {
  .quote__inner {
    grid-column: 3 / -3;
  }
}

.quote__quote {
  font-style: italic;
  margin: 0;
}

.quote__inner::before,
.quote__inner::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 28'%3E%3Cpath fill='%23000209' fill-opacity='.2' d='M10.45 0a3.483 3.483 0 0 0-3.483 3.483 3.483 3.483 0 0 1-3.484 3.484A3.483 3.483 0 0 0 0 10.45v13.933a3.483 3.483 0 0 0 3.483 3.483h13.933a3.483 3.483 0 0 0 3.484-3.483v-6.966a3.483 3.483 0 0 0-3.483-3.484H10.45a3.483 3.483 0 0 1 0-6.966h6.966a3.483 3.483 0 1 0 0-6.967H10.45ZM37.55 0a3.483 3.483 0 0 0-3.483 3.483 3.483 3.483 0 0 1-3.483 3.484A3.483 3.483 0 0 0 27.1 10.45v13.933a3.483 3.483 0 0 0 3.483 3.483h13.934A3.483 3.483 0 0 0 48 24.383v-6.966a3.483 3.483 0 0 0-3.483-3.484H37.55a3.483 3.483 0 1 1 0-6.966h6.967a3.483 3.483 0 1 0 0-6.967H37.55Z'/%3E%3C/svg%3E");
  background-size: 100%;
  content: "";
  display: block;
  height: 28px;
  width: 48px;
}

.quote__inner::after {
  align-self: flex-end;
}

.quote__caption {
  color: var(--charcoal);
  font-size: var(--text-preset-small__font-size);
  line-height: var(--text-preset-small__line-height);
  margin-top: 0.5rem;
  text-align: right;
}
