.one-pager-stage {
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: var(--off-white);
}

.one-pager-stage__inner {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  margin: auto;
  padding: 1.5rem 0;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__inner {
    padding: 2rem 0;
  }
}

.one-pager-stage--light {
  background-color: var(--off-white);
  color: var(--charcoal--darker);
}

.one-pager-stage--light-alt {
  background-color: var(--off-white--darker);
  color: var(--charcoal--darker);
}

.one-pager-stage--blue {
  background-color: var(--blue--lighter);
  color: var(--charcoal--darker);
}

.one-pager-stage--dark {
  background-color: var(--charcoal);
  color: var(--off-white);
}

.one-pager-stage__content {
  align-content: start;
  display: grid;
  position: relative;
  grid-column: 1 / -1;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

@media screen and (min-width: 800px) {
  .one-pager-stage__content {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1280px) {
  .one-pager-stage__content {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.one-pager-stage__content:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__content--reverse {
    direction: rtl;
  }
}

.one-pager-stage__text {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__text {
    grid-column: 5 / -1;
  }

  .one-pager-stage__text--wide {
    grid-column: 3 / -1;
  }
}

@media screen and (min-width: 1280px) {
  .one-pager-stage__text {
    grid-column: 7 / -1;
  }

  .one-pager-stage__text--wide {
    grid-column: 5 / -1;
  }
}

.one-pager-stage__headline {
  margin: 0;
}

.one-pager-stage__text-body p:first-child {
  margin-top: 0.725rem;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__text-body p:first-child {
    margin-top: 1.5rem;
  }
}

.one-pager-stage__text-body p:last-child {
  margin-bottom: 0;
}

.one-pager-stage__text-body a {
  color: var(--blue);
}

.one-pager-stage__text-body a:hover {
  color: var(--blue--lighter);
}

.one-pager-stage--dark .one-pager-stage__text-body a {
  color: var(--blue--lighter);
}

.one-pager-stage--dark .one-pager-stage__text-body a:hover {
  color: var(--blue);
}

.one-pager-stage--blue .one-pager-stage__text-body a:hover {
  color: var(--off-white) !important;
}

.one-pager-stage__media {
  position: relative;
  width: 100%;
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;
}

.one-pager-stage__media > .media__video {
  border-radius: var(--corner-radius-xl);
  width: 100%;
  height: auto;
}

@media screen and (max-width: 799px) {
  .one-pager-stage__media > .media__video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    aspect-ratio: 9 / 16;
  }
}

.one-pager-stage__media > .media__liquid-corner svg {
  fill: var(--off-white);
}

@media screen and (min-width: 800px) {
  .one-pager-stage__media {
    grid-column: 1 / 5;
    margin-bottom: unset;
  }
}

@media screen and (min-width: 1280px) {
  .one-pager-stage__media {
    grid-column: 1 / 7;
  }
}

.one-pager-stage__content--reverse .one-pager-stage__media {
  margin-top: 1.5rem;
  margin-bottom: 0;
  order: 1;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__content--reverse .one-pager-stage__text {
    direction: ltr;
  }
}

@media screen and (min-width: 800px) {
  .one-pager-stage__content--reverse .one-pager-stage__media {
    margin-top: unset;
    order: unset;
  }
}

.one-pager-stage__slider {
  position: relative;
  width: 100%;
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__slider {
    grid-column: 1 / 6;
    margin-bottom: unset;
  }
}

@media screen and (min-width: 1280px) {
  .one-pager-stage__slider {
    grid-column: 1 / 7;
    margin-bottom: unset;
  }
}

.one-pager-stage__form {
  position: relative;
  width: 100%;
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;
  direction: ltr;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__form {
    grid-column: 6 / 10;
    margin-bottom: unset;
  }
}

.one-pager-stage__content--reverse .one-pager-stage__form {
  margin-top: 1.5rem;
  margin-bottom: 0;
  order: 1;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__content--reverse .one-pager-stage__form {
    grid-row: 1;
    margin-top: 0;
  }
}

@media screen and (min-width: 1280px) {
  .one-pager-stage__form {
    grid-column: 7 / 13;
  }
}

/* Slider Embed */

.one-pager-stage__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__container {
    display: grid;
    grid-template-columns: var(--grid-columns);
    grid-column-gap: var(--grid-gap);
  }
}

.one-pager-stage__slide {
  position: relative;
  display: none;
}

.one-pager-stage__slide--active {
  display: block;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__cta {
    grid-column: 5 / -1;
  }
}

@media screen and (min-width: 1280px) {
  .one-pager-stage__cta {
    grid-column: 8 / -1;
  }
}

@media screen and (min-width: 800px) {
  .one-pager-stage__cta {
    justify-self: end;
    align-self: end;
  }
}

.one-pager-stage__navigation {
  width: 100%;
  grid-column: 1 / -1;
  margin-bottom: 1.25rem;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--corner-radius-m);
  padding: 2px;
}

.one-pager-stage__nav-button {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  width: 33.33%;
  height: 36px;
  border-radius: var(--corner-radius-m-nested);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.one-pager-stage__nav-button img {
  height: 1rem;
  width: 1rem;
}

.one-pager-stage__nav-label {
  margin: 0;
  padding-left: 8px;
  font-size: var(--text-preset-paragraph-trimmed__font-size);
}

@media screen and (max-width: 799px) {
  .one-pager-stage__nav-label {
    font-size: 12px;
  }
}

.one-pager-stage__copy {
  font-family: var(--text-preset-paragraph-trimmed__font-family);
  font-size: var(--text-preset-paragraph-trimmed__font-size);
  font-weight: var(--text-preset-paragraph-trimmed__font-weight);
  line-height: var(--text-preset-paragraph-trimmed__line-height);
  letter-spacing: var(--text-preset-paragraph-trimmed__letter-spacing);
  text-align: left;
  margin-bottom: 1rem;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__copy {
    grid-column: 1 / -5;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  .one-pager-stage__copy {
    grid-column: 1 / -6;
    margin-bottom: 0;
  }
}

/* Pseudo-element for active background */
.one-pager-stage__navigation::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(33.33% - 2px);
  height: calc(100% - 4px);
  background-color: var(--off-white--lighter);
  border-radius: 14px;
  transition: transform 0.3s ease;
  z-index: 0;
  transform: translateX(calc(var(--active-index, 0) * 100%));
}

/** Video control */

.one-pager-stage__video-control {
  background-color: var(--color-blue-100);
  color: var(--color-white-100);
  width: 68px;
  height: 48px;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

@media screen and (min-width: 800px) {
  .one-pager-stage__video-control {
    width: 52px;
    height: 40px;
    border-radius: 16px;
  }
}

.one-pager-stage__video-control:hover {
  background-color: var(--color-white-40);
  color: var(--color-blue-100);
}

.one-pager-stage__video-control--active {
  background-color: var(--color-black-40);
  color: var(--color-white-80);
}

.one-pager-stage__video-control--active:hover {
  background-color: var(--color-white-60);
  color: var(--color-black-80);
}
