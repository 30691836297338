.heading {
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: var(--grid-columns);
  margin: 0;
  max-width: var(--max-width);
}

.heading__inner {
  grid-column: span 6;
  margin: 0;
}

@media screen and (min-width: 800px) {
  .heading__inner {
    grid-column: 2 / -2;
  }
}

@media screen and (min-width: 1180px) {
  .heading__inner {
    grid-column: 2 / -3;
  }
}

@media screen and (min-width: 1280px) {
  .heading__inner {
    grid-column: 3 / -3;
  }
}
