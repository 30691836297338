.multi-column {
  position: relative;
}

.multi-column__inner {
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 0;
}

@media screen and (min-width: 800px) {
  .multi-column__inner {
    padding: 4rem 0;
    display: grid;
    grid-template-columns: var(--grid-columns);
    grid-gap: var(--grid-gap);
  }
}

.multi-column--light {
  background-color: var(--off-white);
  color: var(--charcoal--darker);
}

.multi-column--light-alt {
  background-color: var(--off-white--darker);
  color: var(--charcoal--darker);
}

.multi-column--blue {
  background-color: var(--blue--lighter);
  color: var(--charcoal--darker);
}

.multi-column--dark {
  background-color: var(--charcoal);
  color: var(--off-white);
}

.multi-column__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  grid-column: 1 /-1;
}

@media screen and (min-width: 800px) {
  .multi-column__header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .multi-column__inner--2 .multi-column__header,
  .multi-column__inner--6 .multi-column__header,
  .multi-column__inner--10 .multi-column__header {
    grid-column: span 6;
    flex-direction: column;
    justify-content: flex-start;
  }

  .multi-column__inner--5 .multi-column__header,
  .multi-column__inner--9 .multi-column__header {
    grid-column: span 9;
    flex-direction: column;
    justify-content: flex-start;
  }

  .multi-column__inner--7 .multi-column__header {
    grid-column: span 8;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.multi-column__header-content {
  display: flex;
  flex-direction: column;
}

.multi-column__kicker {
  display: block;
  text-transform: uppercase;
}

@media screen and (min-width: 800px) {
  .multi-column__kicker {
    text-transform: uppercase;
  }
}

.multi-column__headline {
  margin: 0;
  padding: 0;
}

.multi-column__paragraph {
  margin: 0;
  padding: 0;
}

.multi-column__button-wrapper {
  flex-shrink: 0;
}

.multi-column__items-rail {
  scroll-snap-type: x mandatory;
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  width: 100vw;
  transform: translateX(calc(var(--grid-horizontal-padding) * -1));
}

.multi-column__items-rail::-webkit-scrollbar-track,
.multi-column__items-rail::-webkit-scrollbar,
.multi-column__items-rail::-webkit-scrollbar-thumb {
  display: none;
}

@media screen and (min-width: 1280px) {
  .multi-column__items-rail {
    display: none;
  }
}

.multi-column__item {
  min-width: calc((100vw - 2 * var(--grid-horizontal-padding)) * 0.9);
  padding-left: var(--grid-horizontal-padding);
  padding-right: 20px;
  scroll-snap-align: start;
}

@media screen and (min-width: 800px) {
  .multi-column__item {
    min-width: calc((100vw / 2 - 2 * var(--grid-horizontal-padding)));
    grid-column: span 4;
  }
}

@media screen and (min-width: 1280px) {
  .multi-column__item {
    min-width: unset;
    padding: 0;
  }

  .multi-column__item--span-3 {
    grid-column: span 3;
  }

  .multi-column__item--span-4 {
    grid-column: span 4;
  }
}

@media screen and (min-width: 800px) {
  .multi-column__item--hidden {
    display: none;
  }
}

.multi-column__inner > .multi-column__item {
  display: none;
}

@media screen and (min-width: 1280px) {
  .multi-column__inner > .multi-column__item {
    display: block;
  }
}

.multi-column__item-headline {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 1rem 0;
}

@media screen and (min-width: 800px) {
  .multi-column__item-headline {
    margin: 0;
    padding: 2rem 0;
  }
}

.multi-column--dark .multi-column__item-headline {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.multi-column__item:not(:has(> .multi-column__item-media)) .multi-column__item-headline {
  border-top: none;
  border-bottom: 1px solid currentcolor;
  padding-top: 0;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.multi-column__item-text {
  margin-bottom: 1rem;
}

.multi-column__item-media {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 800px) {
  .multi-column__item-media {
    margin-bottom: 1.75rem;
  }
}

.multi-column__item-links {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.multi-column__item-links > * {
  margin-bottom: 0.5rem;
}

.multi-column__item-links > *:last-child {
  margin-bottom: 0;
}
