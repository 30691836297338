.tag-list {
  font-size: var(--text-preset-small__font-size);
  line-height: var(--text-preset-small__line-height);
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.tag-list__tag {
  background-color: color-mix(in srgb, var(--charcoal--darker) 10%, transparent);
  border-radius: 100px;
  display: inline-block;
  padding: var(--spacing-12) var(--spacing-16);
  color: var(--charcoal--darker);
}

@media screen and (min-width: 800px) {
  .tag-list__tag {
    padding: var(--spacing-8) var(--spacing-12);
  }
}
