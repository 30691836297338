.tag-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tag-nav__inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--max-width);
}

@media screen and (min-width: 800px) {
  .tag-nav__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
  }
}

.tag-nav__label {
  flex-shrink: 0;
  color: var(--color-black-60);
}

.tag-nav__list-container {
  overflow-x: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tag-nav__list {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  margin: 0;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
}

.tag-nav__item {
  flex-shrink: 0;
}

.tag-nav__link {
  display: inline-block;
  padding: var(--spacing-12) var(--spacing-16);
  background-color: color-mix(in srgb, var(--charcoal--darker) 10%, transparent);
  border-radius: 100px;
  text-decoration: none;
  color: var(--charcoal--darker);
  font-size: 0.875rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  line-height: 10px;
}

.tag-nav__link:hover {
  background-color: var(--color-light-blue-10);
  color: var(--color-blue-100);
}

.tag-nav__link:active {
  background-color: var(--color-black-10);
  color: var(--color-black-80);
}

.tag-nav__link:focus {
  outline: 2px solid var(--color-blue-50);
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--color-blue-50);
}

.tag-nav__link.active {
  background-color: var(--color-black-80);
  color: var(--color-white-80);
}

.tag-nav__list::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 800px) {
  .tag-nav__link {
    padding: var(--spacing-8) var(--spacing-12);
  }
}
