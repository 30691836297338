.accordion {
  position: relative;
}

.accordion__inner {
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: var(--grid-columns);
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  padding-bottom: 3rem;
  padding-top: 3rem;
}

@media screen and (min-width: 800px) {
  .accordion__inner {
    padding-bottom: 7.5rem;
    padding-top: 7.5rem;
  }
}

.accordion--light {
  background-color: var(--off-white);
  color: var(--charcoal--darker);
}

.accordion--light-alt {
  background-color: var(--off-white--darker);
  color: var(--charcoal--darker);
}

.accordion--blue {
  background-color: var(--blue--lighter);
  color: var(--charcoal--darker);
}

.accordion__header {
  align-items: start;
  display: flex;
  grid-column: span 12;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@media screen and (min-width: 1280px) {
  .accordion__header {
    grid-column: span 4;
  }
}

.accordion__kicker {
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}

@media screen and (min-width: 800px) {
  .accordion__kicker {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}

.accordion__headline {
  margin: 0;
  padding: 0;
}

.accordion__items {
  grid-column: span 12;
}

@media screen and (min-width: 1280px) {
  .accordion__items {
    grid-column: span 8;
  }
}

.accordion__item {
  border-top: 1px solid currentcolor;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.accordion__item-header {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.accordion__item-headline {
  margin-bottom: 0;
  margin-top: 0;
  user-select: none;
}

.accordion__item-toggles {
  display: flex;
  user-select: none;
}

/* csslint-disable-next-line */
.accordion__item-toggles .icon {
  height: 2rem;
  width: 2rem;
}

.accordion__item-toggle-close {
  display: block;
}

.accordion__item-toggle-open {
  display: none;
}

.accordion__item-content {
  margin-top: 1.5rem;
}

.accordion__item-text {
  margin-bottom: 1rem;
}

.accordion__item--collapsed .accordion__item-content {
  display: none;
}

.accordion__item--collapsed .accordion__item-toggle-close {
  display: none;
}

.accordion__item--collapsed .accordion__item-toggle-open {
  display: block;
}

.accordion__item-link {
  margin-bottom: 1.5rem;
}
