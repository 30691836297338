.news-banner {
  display: none;
  background-color: var(--blue);
  padding: 0.625rem var(--grid-horizontal-padding);
}

.navigation__nav--active .news-banner {
  display: none;
}

.news-banner.news-banner--show {
  display: block;
}

.news-banner__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--off-white--lighter);
  max-width: var(--max-width);
  margin: auto;
}

.news-banner__content {
  display: flex;
}

@media screen and (min-width: 800px) {
  .news-banner__content {
    align-items: center;
  }
}

.news-banner__content > label {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.news-banner__content p {
  padding: 0;
  margin: 0;
  font-size: var(--text-preset-small__font-size);
}

.news-banner__content a {
  text-decoration: underline;
}

.news-banner__close {
  width: 24px;
  height: 24px;
  display: flex;
  font-size: 20px;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--off-white--lighter);
}

.news-banner__bubble {
  padding: 0 8px;
  border-radius: 9999px;
  font-size: var(--text-preset-small__font-size);
  background-color: var(--off-white--lighter);
  color: var(--blue);
  display: inline-block;
  margin-right: 0.675rem;
}
