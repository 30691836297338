.tiles-collection__item-large {
  padding-bottom: 2rem;
}

@media screen and (min-width: 800px) {
  .tiles-collection__item-large {
    padding-bottom: 4rem;
  }
}

.tiles-collection__items {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tiles-collection__mobile-rail {
  flex-direction: row;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  width: 100vw;
  gap: calc(1.5rem - var(--grid-horizontal-padding));
  transform: translateX(calc(var(--grid-horizontal-padding) * -1));
  padding: 0 var(--grid-horizontal-padding);
}

.tile-collection__mobile-rail::-webkit-scrollbar-track,
.tile-collection__mobile-rail::-webkit-scrollbar,
.tile-collection__mobile-rail::-webkit-scrollbar-thumb {
  display: none;
}

@media screen and (min-width: 800px) {
  .tiles-collection__items:not(.tiles-collection__mobile-rail) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .tiles-collection__items:has(.tiles-collection__item:first-child:nth-last-child(2)) {
    display: flex;
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1280px) {
  .tiles-collection__items,
  .tiles-collection__mobile-rail {
    display: grid;
    gap: 1.5rem;
    grid-column-gap: var(--grid-gap);
    grid-template-columns: var(--grid-columns);
    overflow-x: unset;
    margin: 0;
    padding: 0;
    width: auto;
    transform: unset;
  }
}

.tiles-collection__item {
  min-width: calc((100vw - 2 * var(--grid-horizontal-padding)) * 0.9);
}

.tiles-collection__mobile-rail .tiles-collection__item {
  scroll-snap-align: start;
  padding-left: var(--grid-horizontal-padding);
}

@media screen and (min-width: 800px) {
  .tiles-collection__item {
    min-width: unset;
    width: calc((100% - 1.5rem) / 2);
  }

  .tiles-collection__mobile-rail .tiles-collection__item {
    min-width: calc(((100vw / 2) - 2 * var(--grid-horizontal-padding)) * 0.9);
  }

  /* two items */
  .tiles-collection__item:first-child:nth-last-child(2),
  .tiles-collection__item:first-child:nth-last-child(2) ~ .tiles-collection__item {
    min-width: unset;
    padding: 0;
  }
}

@media screen and (min-width: 1280px) {
  .tiles-collection__item,
  .tiles-collection__mobile-rail .tiles-collection__item {
    min-width: unset;
    width: unset;
    padding-left: 0;
  }

  /* two items */
  .tiles-collection__item:first-child:nth-last-child(2),
  .tiles-collection__item:first-child:nth-last-child(2) ~ .tiles-collection__item {
    grid-column: span 6;
  }

  /* three items */

  /* stylelint-disable-next-line  */
  .tiles-collection__item:first-child:nth-last-child(3),
  .tiles-collection__item:first-child:nth-last-child(3) ~ .tiles-collection__item {
    grid-column: span 4;
  }

  /* more than three items */

  /* stylelint-disable-next-line  */
  .tiles-collection__item:first-child:nth-last-child(n + 4),
  .tiles-collection__item:first-child:nth-last-child(n + 4) ~ .tiles-collection__item {
    grid-column: span 4;
  }
}

/* stylelint-disable-next-line  */
.tiles-collection .header .button__label {
  padding: 0;
}
