.tag {
  background-color: color-mix(in srgb, var(--charcoal--darker) 10%, transparent);
  border-radius: 100px;
  border: none;
  display: inline-block;
  margin-bottom: var(--spacing-8);
  padding: var(--spacing-12) var(--spacing-16);
  color: var(--charcoal--darker);
  cursor: pointer;
}

.tag:hover {
  background-color: var(--color-light-blue-10);
  color: var(--color-blue-100);
}

.tag:active {
  background-color: var(--color-black-10);
  color: var(--color-black-80);
}

.tag:focus {
  /* stylelint-disable-next-line */
  outline: none;
  box-shadow: 0 0 0 2px var(--color-blue-50);
}

@media screen and (min-width: 800px) {
  .tag {
    padding: var(--spacing-8) var(--spacing-12);
  }
}
