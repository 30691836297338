.text-video {
  position: relative;
}

.text-video__inner {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 800px) {
  .text-video__inner {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.text-video--light {
  background-color: var(--off-white);
  color: var(--charcoal--darker);
}

.text-video--light-alt {
  background-color: var(--off-white--darker);
  color: var(--charcoal--darker);
}

.text-video--blue {
  background-color: var(--blue--lighter);
  color: var(--charcoal--darker);
}

.text-video--dark {
  background-color: var(--charcoal);
  color: var(--off-white);
}

.text-video__header {
  border-bottom: 1px solid currentcolor;
  margin-bottom: 1rem;
}

@media screen and (min-width: 800px) {
  .text-video__header {
    margin-bottom: 1.5rem;
  }
}

.text-video__kicker {
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

@media screen and (min-width: 800px) {
  .text-video__kicker {
    margin-bottom: 0.5rem;
  }
}

.text-video__headline {
  margin-bottom: 1rem;
}

@media screen and (min-width: 800px) {
  .text-video__headline {
    margin-bottom: 1.5rem;
  }
}

.text-video__content {
  align-content: start;
  display: grid;
  position: relative;
  grid-column: 1 / -1;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

@media screen and (min-width: 800px) {
  .text-video__content {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1280px) {
  .text-video__content {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.text-video__content:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .text-video__content--reverse {
    direction: rtl;
  }
}

.text-video__text {
  grid-column: 1 / -1;
  font-weight: 400;
}

@media screen and (min-width: 800px) {
  .text-video__text {
    grid-column: 5 / -1;
  }
}

@media screen and (min-width: 1280px) {
  .text-video__text {
    grid-column: 7 / -1;
  }
}

/* csslint-disable-next-line */
.text_video__text-body a {
  color: var(--blue);
}

/* csslint-disable-next-line */
.text_video__text-body a:hover {
  color: var(--blue--lighter);
}

/* csslint-disable-next-line */
.text-video--dark .text_video__text-body a {
  color: var(--blue--lighter);
}

/* csslint-disable-next-line */
.text-video--dark .text_video__text-body a:hover {
  color: var(--blue);
}

/* csslint-disable-next-line */
.text-video--blue .text_video__text-body a:hover {
  color: var(--off-white) !important;
}

.text-video__links {
  margin-top: 1.75rem;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .text-video__links {
    margin-top: 2rem;
  }
}

.text-video__media {
  position: relative;
  width: 100%;
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 800px) {
  .text-video__media {
    grid-column: 1 / 5;
    margin-bottom: unset;
  }
}

@media screen and (min-width: 1280px) {
  .text-video__media {
    grid-column: 1 / 7;
  }
}

.text-video__content--reverse .text-video__media {
  margin-top: 1.5rem;
  margin-bottom: 0;
  order: 1;
}

@media screen and (min-width: 800px) {
  .text-video__content--reverse .text-video__text {
    direction: ltr;
  }
}

@media screen and (min-width: 800px) {
  .text-video__content--reverse .text-video__media {
    margin-top: unset;
    order: unset;
  }
}
