.header {
  align-items: flex-end;
  display: flex;
  grid-column: 1 / -1;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media screen and (min-width: 800px) {
  .header {
    gap: 1.5rem;
  }
}

.header__kicker {
  display: block;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}

@media screen and (min-width: 800px) {
  .header__kicker {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}

.header__headline {
  margin: 0;
  padding: 0;
}

.header__link {
  margin-bottom: -0.4rem;
}
