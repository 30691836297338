.navigation {
  --nav-spacing-01: 0.5rem;
  --nav-spacing-02: 0.75rem;
  --nav-spacing-03: 1rem;
  --nav-spacing-04: 1.25rem;
  --transition-duration: 0.2s;
  --transition-easing: ease-in-out;
}

.navigation--spacing-top {
  height: var(--nav-height);
}

.navigation__nav {
  background-color: rgba(0, 0, 0, 0);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(0);
  transition:
    transform var(--transition-duration) var(--transition-easing),
    background-color var(--transition-duration) var(--transition-easing);
  transition-delay: 0, var(--transition-duration);
  z-index: 10;
}

.navigation__nav--inline {
  position: relative;
}

.navigation__nav,
.navigation__nav--light {
  background-color: var(--off-white);
}

.navigation__nav--dark {
  background-color: var(--color-background-100);
}

.navigation__nav--transparent {
  background-color: transparent;
}

.navigation__nav--transparent.navigation__nav--scrolling-up {
  background-color: var(--off-white);
}

.navigation__nav--active,
.navigation__nav--active.navigation__nav--transparent {
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--color-background-100);
}

/* csslint-disable-next-line */
.navigation__nav--active .news-banner {
  display: none !important;
}

.navigation__container {
  padding: 0 var(--grid-horizontal-padding);
  height: var(--nav-height);
}

.navigation__content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 0 auto;
  margin-bottom: -1px;
  height: 100%;
  max-width: var(--max-width);
}

.navigation__nav--active .navigation__content {
  border-bottom: 1px solid color-mix(in srgb, var(--off-white--lighter) 60%, transparent);
}

.navigation__logo {
  align-self: center;
}

.navigation__logo svg {
  height: 20px;
  width: 36px;
}

@media screen and (min-width: 800px) {
  .navigation__logo svg {
    height: 24px;
    width: 43px;
  }
}

.navigation__link-icon {
  display: inline-flex;
  align-items: center;
  color: currentColor;
  height: 1rem;
  width: 1rem;
}

/* MAIN NAVIGATION BAR */

.navigation__navbar {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  height: 100%;
}

.navigation__navbar-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation__navbar-item,
.navigation__navbar-item--dropdown {
  box-sizing: border-box;
  display: none;
}

@media screen and (min-width: 800px) {
  .navigation__navbar-item {
    color: var(--charcoal--darker);
    font-size: var(--text-preset-small__font-size);
    display: flex;
    outline: none; /* stylelint-disable-line */
    border-radius: 1rem;
    padding: 0.625rem 1rem;
    text-align: center;
    text-decoration: none;
  }
}

.navigation__navbar-item:focus-within,
.navigation__navbar-item:not(.navigation__navbar-dropdown-label):hover {
  color: var(--blue);
  background-color: color-mix(in srgb, var(--blue) 10%, transparent);
}

@media screen and (min-width: 800px) {
  .navigation__navbar-item--dropdown {
    display: block;
    box-sizing: border-box;
    position: relative;
  }
}

.navigation__navbar-item--dropdown::before {
  content: "";
  display: none;
  height: 1rem;
  width: 1rem;
  background-color: var(--off-white--lighter);
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 10;
  clip-path: path("M0 0v16h16C7.164 16 0 8.836 0 0Z");
}

.navigation__navbar-item--dropdown:hover,
.navigation__navbar-item--dropdown:focus,
.navigation__navbar-item--dropdown:focus-within {
  color: var(--charcoal--darker);
  background-color: var(--off-white--lighter);
  border-radius: 1rem 1rem 0 0;
  outline: none; /* stylelint-disable-line */
}

.navigation__navbar-item--dropdown:hover.navigation__navbar-item--dropdown::before,
.navigation__navbar-item--dropdown:focus.navigation__navbar-item--dropdown::before,
.navigation__navbar-item--dropdown:focus-within.navigation__navbar-item--dropdown::before {
  display: block;
}

.navigation__navbar-dropdown-label {
  align-items: center;
  color: var(--charcoal--darker);
  font-size: var(--text-preset-small__font-size);
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
}

.navigation__navbar-dropdown-label::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23000' fill-opacity='.8' d='M9.678 5.01 7 7.683 4.322 5.011l-.822.822 3.5 3.5 3.5-3.5-.822-.822Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h14v14H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  height: var(--text-preset-small__font-size);
  margin-left: 0.3em;
  width: var(--text-preset-small__font-size);
}

.navigation__navbar-dropdown {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}

.navigation__navbar-dropdown-list {
  background-color: var(--off-white--lighter);
  display: block;
  list-style: none;
  border-radius: 0 1rem 1rem 1rem;
  min-width: 180px;
  padding: 0;
  position: relative;
  overflow: hidden;
  text-align: left;
  width: 280px;
}

.navigation__navbar-dropdown-label:focus + .navigation__navbar-dropdown,
.navigation__navbar-item--dropdown:hover .navigation__navbar-dropdown,
.navigation__navbar-item--dropdown:focus-within .navigation__navbar-dropdown {
  height: auto;
  opacity: 1;
  width: auto;
}

.navigation__navbar-dropdown-item {
  display: block;
}

.navigation__navbar-dropdown-item:not(:last-child) {
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.navigation__navbar-dropdown-link {
  color: var(--charcoal--darker);
  font-size: var(--text-preset-small__font-size);
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  text-decoration: none;
}

.navigation__navbar-dropdown-link:hover,
.navigation__navbar-dropdown-link:focus {
  background-color: color-mix(in srgb, var(--blue) 10%, transparent);
  color: var(--blue);
  outline: none; /* stylelint-disable-line */
}

.navigation__navbar-link {
  align-items: center;
  font-size: var(--text-preset-small__font-size);
  display: flex;
  gap: 4px;
  letter-spacing: 0.01em;
  outline: none; /* stylelint-disable-line */
  text-decoration: none;
  vertical-align: middle;
}

.navigation__navbar-link:hover,
.navigation__navbar-link:focus {
  background-color: color-mix(in srgb var(--blue--lighter) 10%, transparent);
}

.navigation__navbar-button-text {
  display: none;
}

@media screen and (min-width: 1024px) {
  .navigation__navbar-button-text {
    display: block;
  }
}

/* BURGER NAV / OVERLAY */

.navigation__burger {
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCAxMEgwdjJoMjR2LTJ6bTAgMTBIMHYyaDI0di0yeiIgZmlsbD0iIzJGMzAzMyIvPjwvc3ZnPg==");
  background-size: 100%;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 12px;
  top: 6px;
  width: 24px;
  z-index: 20;
}

@media screen and (min-width: 800px) {
  .navigation__burger {
    display: none;
  }
}

.navigation__nav--active .navigation__burger {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxNy40MTRsNy43NzggNy43NzggMS40MTUtMS40MTRMMTMuNDEzIDE2bDcuNzc4LTcuNzc4LTEuNDE0LTEuNDE0TDEyIDE0LjU4NiA0LjIyMiA2LjgwOCAyLjgwOCA4LjIyMiAxMC41ODYgMTZsLTcuNzc4IDcuNzc4IDEuNDE0IDEuNDE0TDEyIDE3LjQxNHoiIGZpbGw9IiNFREVDRUEiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwMCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgyNHYzMkgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==");
}

/** The '.navigation__nav--has-news-banner' class is added using an effect on the news banner component */

/* csslint-disable-next-line */
.navigation__nav--has-news-banner:not(.navigation__nav--active) .navigation__burger {
  top: calc(var(--newsBannerHeight) + 15px);
}

.navigation__slidein-overlay {
  bottom: 0;
  height: 100vh;
  left: 0;
  margin-top: 45px;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform var(--transition-duration) var(--transition-easing);
  width: 100%;
  z-index: 10;
  transform: translateX(100%);
}

.navigation__nav--active .navigation__container,
.navigation__nav--active .navigation__slidein-overlay {
  backdrop-filter: blur(15px);
}

.navigation__nav--active .navigation__slidein-overlay {
  transform: translateX(0);
}

.navigation__slidein-nav-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  cursor: default;
  display: block;
  height: 100%;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  scroll-behavior: smooth;
  width: 100%;
}

.navigation__slidein-nav {
  padding: 0 var(--grid-horizontal-padding);
}

.navigation__slidein-nav-list {
  margin: 0;
  padding: 0;
}

.navigation__slidein-nav-item {
  border-bottom: 1px solid color-mix(in srgb, var(--off-white--lighter) 30%, transparent);
  list-style: none;
  display: block;
  outline: none; /* stylelint-disable-line */
  padding: 1rem 0;
  user-select: none;
}

.navigation__slidein-nav .navigation__link-icon {
  color: color-mix(in srgb, var(--off-white--lighter) 40%, transparent);
}

.navigation__slidein-nav-item:last-child {
  border-bottom: none;
}

.navigation__slidein-nav-sublabel {
  border-bottom: 1px solid color-mix(in srgb, var(--off-white--lighter) 15%, transparent);
  color: color-mix(in srgb, var(--off-white--lighter) 60%, transparent);
  display: block;
  padding-bottom: 1rem;
}

.navigation__slidein-nav-subitem {
  border-bottom: 1px solid color-mix(in srgb, var(--off-white--lighter) 15%, transparent);
  padding: 1rem 0 1rem 1.5rem;
  list-style: none;
  user-select: none;
}

.navigation__slidein-nav-subitem:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.navigation__slidein-nav-sublist {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation__slidein-nav-link {
  cursor: pointer;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  outline: none; /* stylelint-disable-line */
  color: color-mix(in srgb, var(--off-white--lighter) 90%, transparent);
}
