.full-screen-media {
  background-color: var(--off-white);
}

.full-screen-media__inner {
  max-width: var(--max-width);
  padding: 1.5rem 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media screen and (min-width: 1200px) {
  .full-screen-media__inner {
    padding: 2rem 0;
  }
}

.full-screen-media__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (min-width: 1200px) {
  .full-screen-media__header {
    flex-direction: row;
    gap: 2rem;
  }
}

.full-screen-media__headline {
  margin: 0;
}

.full-screen-media__text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media screen and (min-width: 1200px) {
  .full-screen-media__headline,
  .full-screen-media__text:has(.full-screen-media__text-body) {
    flex: 1 0;
  }
}

.full-screen-media__text-body p {
  margin: 0;
}
