.media-toggle__inner {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: var(--max-width);
  margin: auto;
  padding: 1.5rem 0;
}

@media screen and (min-width: 1280px) {
  .media-toggle__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    padding: 2rem 0;
  }
}

.media-toggle__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.5rem;
}

.media-toggle__icon svg {
  height: 4rem;
  width: 4rem;
}

.media-toggle__icon img {
  max-width: 50%;
}

.media-toggle__headline,
.media-toggle__copy {
  margin: 0;
}

.media-toggle__slider {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.media-toggle__slide {
  display: none;
}

.media-toggle__slide--active {
  display: block;
}

.media-toggle__hero {
  width: 100%;
  text-align: center;
}

.media-toggle__image-container {
  width: 100%;
  height: 60vh;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.media-toggle__hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-toggle__navigation {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--corner-radius-l);
  padding: 2px;
}

@media screen and (min-width: 1280px) {
  .media-toggle__navigation {
    border-radius: var(--corner-radius-m);
  }
}

/* Pseudo-element for active background */
.media-toggle__navigation::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc((100% - 4px) / var(--slides-count));
  height: calc(100% - 4px);
  background-color: var(--off-white);
  border-radius: var(--corner-radius-l-nested);
  transition: transform 0.3s ease;
  z-index: 0;
  transform: translateX(calc(var(--active-index, 0) * 100%));
}

@media screen and (min-width: 1280px) {
  .media-toggle__navigation::before {
    border-radius: var(--corner-radius-m-nested);
  }
}

.media-toggle__nav-button {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  padding: 0.875rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

@media screen and (min-width: 1280px) {
  .media-toggle__nav-button {
    padding: 0.625rem 0;
  }
}

.media-toggle__nav-button svg {
  height: 1rem;
  width: 1rem;
  fill: var(--charcoal);
}

.media-toggle__nav-button--active svg {
  fill: var(--charcoal--darker);
}
