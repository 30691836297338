.related-articles {
  background-color: var(--off-white--darker);
}

.related-articles--top-spacing {
  padding-top: 5rem;
}

.related-articles__inner {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 0;
}
