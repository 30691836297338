.team-overlay {
  background-color: #000209;
  opacity: 0.4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: none;
}

.team-overlay--visible {
  display: block;
}

.team {
  position: relative;
  background-color: var(--off-white);
}

.team__inner {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 800px) {
  .team__inner {
    display: grid;
    grid-template-columns: var(--grid-columns);
    grid-column-gap: var(--grid-gap);
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

.team__header {
  grid-column: 1 / -1;
  margin-bottom: var(--spacing-32);
}

@media screen and (max-width: 799px) {
  .team__header--desktop {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .team__header--desktop {
    grid-column: 1 / -2;
  }

  .team__header--mobile {
    display: none;
  }
}

.team__kicker {
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

@media screen and (min-width: 800px) {
  .team__kicker {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
}

.team__headline {
  margin: 0;
  padding: 0;
}

.team__header-paragraph {
  margin-top: 1rem;
}

.team__members {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  flex-wrap: nowrap;
  width: 100%;
  gap: var(--spacing-24);
}

@media screen and (min-width: 800px) {
  .team__members {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--spacing-32);
    width: 100%;
    overflow: hidden;
  }
}

.team__member {
  position: relative;
  min-width: 70vw;
}

@media screen and (min-width: 800px) {
  .team__member {
    width: 100%;
    min-width: auto;
  }
}

.team__member-header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.team__member-image {
  width: 100%;
  margin-bottom: var(--spacing-16);
}

/* csslint-disable-next-line */
.team__member-image > * {
  display: block;
}

.team__member-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team__member-show-more {
  cursor: pointer;
}

.team__member-show-more--hidden {
  display: none;
}

.team__member-show-less {
  cursor: pointer;
  grid-column: span 1;
  grid-column-start: 2;
  grid-row-start: 1;
}

@media screen and (min-width: 800px) {
  .team__member-show-less {
    grid-column-start: 3;
    justify-self: end;
  }
}

.team__member-show-less .button {
  background-color: var(--color-light-blue-10);
}

.team__member-show-less .button__icon {
  color: var(--color-light-blue-100);
}

.team__member-position {
  margin: 0;
  margin-bottom: 0.25rem;
  color: var(--color-black-60);
}

@media screen and (min-width: 800px) {
  .team__member-position {
    margin-bottom: 0.5rem;
  }
}

.team__member-name {
  margin: 0;
  margin-bottom: 0.25rem;
}

@media screen and (min-width: 800px) {
  .team__member-name {
    margin-bottom: 0.75rem;
  }
}

.team__member-info-box {
  display: flex;
  justify-content: space-between;
}

.team__member-bio {
  width: 100%;
  max-width: 640px;
  background-color: rgba(49, 51, 56, 1);
  position: fixed;
  top: 0;
  right: 0;
  padding: var(--spacing-12);
  height: 100vh;
  z-index: 9999;
  overflow-y: scroll;
  transition: transform 0.3s ease;
  transform: translate3d(100%, 0, 0);
}

@media screen and (min-width: 800px) {
  .team__member-bio {
    padding: var(--spacing-32);
  }
}

.team__member-bio p {
  color: var(--color-white-80);
}

/* csslint-disable-next-line */
.team__member-bio > p:first-child {
  margin-top: 0;
}

/* csslint-disable-next-line */
.team__member-bio > p:last-child {
  margin-bottom: 0;
}

.team__member-bio--expanded {
  transform: translate3d(0, 0, 0);
}

.team__member-info-social {
  display: none;
}

@media screen and (min-width: 800px) {
  .team__member-info-social {
    display: flex;
    justify-content: flex-end;
  }
}

.team__member-info-social-icon {
  padding: var(--spacing-12) var(--spacing-16);
  background-color: var(--color-light-blue-10);
  border-radius: var(--corner-radius-m);
}

.team__member-info-social-icon > svg {
  color: var(--color-light-blue-100);
  width: 1rem;
  height: 1rem;
}

.team__member-bio-social {
  display: flex;
  justify-content: flex-start;
  gap: var(--spacing-8);
  margin-top: var(--spacing-24);
}

.team__member:nth-child(even) .team__member-bio-social {
  justify-content: flex-start;
}

.team__member-bio-row {
  display: grid;
  gap: var(--spacing-16);
  margin-bottom: var(--spacing-24);
}

@media screen and (min-width: 800px) {
  .team__member-bio-row {
    grid-template-columns: 1.5fr 1fr 1fr;
  }
}

.team__member-bio-member-image {
  grid-column: span 1;
}

.team__member-bio-member-image path {
  fill: rgba(49, 51, 56, 1);
}

.team__member-bio-info {
  grid-column: span 1;
  grid-column-start: 1;
  color: var(--color-white-80);
}

@media screen and (min-width: 800px) {
  .team__member-bio-info {
    grid-column: span 1;
    grid-column-start: 2;
  }
}

.team__member-bio-info .team__member-position {
  color: var(--color-white-60);
}
