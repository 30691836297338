.partnership-stage__inner {
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
  margin: auto;
  padding: 1.5rem 0;
}

@media screen and (min-width: 800px) {
  .partnership-stage__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.partnership-stage__logo {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (min-width: 800px) {
  .partnership-stage__logo {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.partnership-stage__logo svg,
.partnership-stage__logo img {
  max-width: 100px;
  width: 100%;
  height: unset;
  object-fit: contain;
}

@media screen and (min-width: 800px) {
  .partnership-stage__logo svg,
  .partnership-stage__logo img {
    max-width: 150px;
  }
}

.partnership-stage__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.partnership-stage__title {
  margin: 0;
}

.partnership-stage__copy {
  margin-bottom: 0;
  margin-top: 1rem;
}
