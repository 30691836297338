.text {
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: var(--grid-columns);
  margin: 0;
  max-width: var(--max-width);
}

.text:last-child {
  margin-bottom: 0;
}

.text__inner {
  grid-column: span 6;
}

@media screen and (min-width: 800px) {
  .text__inner {
    grid-column: 2 / -2;
  }
}

@media screen and (min-width: 1180px) {
  .text__inner {
    grid-column: 3 / -3;
  }
}

@media screen and (min-width: 1280px) {
  .text__inner {
    grid-column: 4 / -4;
  }
}

/* csslint-disable-next-line */
.text p {
  font-family: var(--text-preset-paragraph-trimmed__font-family);
  font-size: var(--text-preset-paragraph-trimmed__font-size);
  font-weight: var(--text-preset-paragraph-trimmed__font-weight);
  line-height: var(--text-preset-paragraph-trimmed__line-height);
  letter-spacing: var(--text-preset-paragraph-trimmed__letter-spacing);
  margin: 0 0 1.5rem;
}

/* csslint-disable-next-line */
.text p:last-child {
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.text a {
  color: var(--blue--darker);
}
