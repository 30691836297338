.basic-stage {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: var(--off-white);
}

.basic-stage__inner {
  position: relative;
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  gap: 2rem;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.basic-stage__content {
  grid-column: 1 / -1;
  align-self: end;
  order: 2;
}

.basic-stage__media {
  position: relative;
  grid-column: 1 / -1;
  order: 1;
}

@media screen and (min-width: 800px) {
  .basic-stage__content {
    order: 1;
    grid-column: span 4;
  }

  .basic-stage__media {
    order: 2;
    grid-column: span 4;
  }

  .basic-stage__content--full {
    grid-column: span 6;
  }
}

@media screen and (min-width: 1280px) {
  .basic-stage__content {
    order: 1;
    grid-column: span 6;
  }

  .basic-stage__media {
    order: 2;
    grid-column: span 6;
  }

  .basic-stage__content--full {
    grid-column: span 8;
  }
}
