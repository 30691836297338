.cookiebar {
  display: none;
}

.cookiebar--active {
  background-color: var(--charcoal);
  bottom: 0;
  display: block;
  color: var(--off-white);
  left: 0;
  position: fixed;
  right: 0;
  z-index: 11;
}

.cookiebar__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0.75rem;
  max-width: var(--max-width);
  margin: auto;
}

@media screen and (min-width: 1050px) {
  .cookiebar__content {
    flex-direction: row;
    gap: 2rem;
    padding: 1rem;
  }
}

.cookiebar__text > p {
  margin: 0;
}

.cookiebar__buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 0 0 auto;
}

@media screen and (min-width: 800px) {
  .cookiebar__buttons {
    flex-direction: row;
    min-width: 450px;
    width: 100%;
    align-items: center;
  }
}

@media screen and (min-width: 1050px) {
  .cookiebar__buttons {
    text-align: right;
    width: unset;
  }
}

.cookiebar__button {
  background-color: color-mix(in srgb, var(--blue) 10%, transparent);
  border: none;
  border-radius: 12px;
  color: var(--blue--lighter);
  cursor: pointer;
  line-height: 1.15;
  padding: 12px 32px;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 1050px) {
  .cookiebar__button {
    width: unset;
  }
}

.cookiebar__button:hover {
  background-color: color-mix(in srgb, var(--blue) 20%, transparent);
}
