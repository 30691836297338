.use-case-collection {
  padding: 2rem var(--grid-horizontal-padding);
}

.use-case-collection__inner {
  margin: auto;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.use-case-collection__header {
  display: grid;
  gap: 2rem;
}

@media screen and (min-width: 800px) {
  .use-case-collection__header {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 799px) {
  .use-case-collection__filter {
    overflow-x: hidden;
    max-width: calc(100% + 2 * var(--grid-horizontal-padding));
    margin-left: calc(-1 * var(--grid-horizontal-padding));
    margin-right: calc(-1 * var(--grid-horizontal-padding));
  }

  .use-case-collection__filter-row {
    display: flex;
    gap: var(--spacing-8);
    overflow-x: auto;
    white-space: nowrap;
    padding-left: var(--grid-horizontal-padding);
    padding-right: var(--grid-horizontal-padding);
  }

  .use-case-collection__filter-row::-webkit-scrollbar {
    display: none;
  }

  .use-case-collection__filter-headline {
    padding-left: var(--grid-horizontal-padding);
    padding-right: var(--grid-horizontal-padding);
    color: var(--color-black-60);
  }
}

.use-case-collection__headline {
  margin: 0;
}

.use-case-collection__description {
  margin-bottom: 0;
  margin-top: 1rem;
}

@media screen and (min-width: 800px) {
  .use-case-collection__description {
    margin-top: 1.5rem;
  }
}

.use-case-collection__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 2rem;
}

@media screen and (min-width: 800px) {
  .use-case-collection__list {
    grid-template-columns: 1fr 1fr;
  }
}

.use-case-item {
  transition:
    opacity 0.2s ease,
    transform 0.2s ease;
  opacity: 1;
  transform: scale(1);
}

.use-case-item[style*="display: none"] {
  opacity: 0;
  transform: scale(0.9);
}
