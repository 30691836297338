.basic-text {
  background-color: var(--off-white);
  position: relative;
}

.basic-text__inner {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  margin: auto;
  padding: 1.5rem 0;
}

@media screen and (min-width: 800px) {
  .basic-text__inner {
    padding: 2rem 0;
  }
}

.basic-text__content {
  grid-column: 1 / -1;
}

@media screen and (min-width: 800px) {
  .basic-text__content {
    grid-column: 1 / span 6;
  }
}

@media screen and (min-width: 1280px) {
  .basic-text__content {
    grid-column: 1 / span 8;
  }
}

.basic-text__headline {
  margin: 0;
}

.basic-text__headline:has(~ p) {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 800px) {
  .basic-text__headline:has(~ p) {
    margin-bottom: 2rem;
  }
}

.basic-text ol,
.basic-text ul {
  padding-left: 1.5em;
}

.basic-text p + ul {
  margin-top: -1em;
}

.basic-text li:last-child {
  margin-bottom: 0;
}
