.image {
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: var(--grid-columns);
  margin: 0;
  max-width: var(--max-width);
}

.image__inner {
  grid-column: span 6;
  margin: 0;
}

@media screen and (min-width: 800px) {
  .image__inner {
    grid-column: 2 / -2;
  }
}

@media screen and (min-width: 1180px) {
  .image__inner {
    grid-column: 2 / -3;
  }
}

@media screen and (min-width: 1280px) {
  .image__inner {
    grid-column: 3 / -3;
  }
}

.image__caption {
  color: var(--charcoal);
  font-size: var(--text-preset-small__font-size);
  line-height: var(--text-preset-small__line-height);
  margin-top: 0.5rem;
  text-align: right;
}
