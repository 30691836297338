:root {
  --grid-gap: 1.5rem;
  --grid-columns: repeat(6, minmax(0, 1fr));
  --max-width: 1280px;
  --grid-horizontal-padding: 12px;
}

@media screen and (min-width: 800px) {
  :root {
    --grid-gap: 2rem;
    --grid-columns: repeat(8, minmax(0, 1fr));
    --grid-horizontal-padding: 40px;
  }
}

@media screen and (min-width: 1280px) {
  :root {
    --grid-columns: repeat(12, minmax(0, 1fr));
  }
}
