:root {
  --font-size-large: 1.125rem;
  --color-brand: #ff0800;
  --color-background: #fff;
  --color-text-primary: var(--color-black-80);
  --color-text-subtle: var(--color-black-60);
  --color-text-inverted: #fff;
  --color-border-primary: #000;
  --spacing-smallest: 0.25rem;
  --color-divider: var(--color-black-10);

  /* Forms */
  --color-input-light-bg: var(--color-black-5);
  --color-input-light-placeholder: var(--color-black-40);
  --color-input-light-bg-hover: var(--color-blue-10);
  --color-input-light-border-focus: var(--color-blue-50);
  --color-input-light-bg-disabled: var(--color-black-5);
  --color-input-light-bg-disabled-placeholder: var(--color-black-20);
  --color-input-light-border-error: var(--error--dark);

  /* Corner Radius Variables */
  --corner-radius-xl: 24px; /* Large Containers (Desktop Full screen images, Chat Windows) */
  --corner-radius-l: 20px; /* Containers at least 48px high (mobile buttons, mobile inputs, cards) */
  --corner-radius-l-nested: 18px; /* Nested containers with parent using L corner radius and 2px padding (mobile button icon container) */
  --corner-radius-m: 16px; /* Containers at least 40px high (buttons, chat bubbles, inputs) */
  --corner-radius-m-nested: 14px; /* Nested containers with parent using M corner radius and 2px padding (button icon container) */
  --corner-radius-s: 12px; /* Containers smaller than 40px high (tags) */

  /* Spacings */
  --spacing-128: 8rem;
  --spacing-96: 6rem;
  --spacing-64: 4rem;
  --spacing-48: 3rem;
  --spacing-32: 2rem;
  --spacing-24: 1.5rem;
  --spacing-20: 1.25rem;
  --spacing-16: 1rem;
  --spacing-12: 0.75rem;
  --spacing-8: 0.5rem;
  --spacing-4: 0.25rem;
  --spacing-2: 0.125rem;
  --spacing-minus-24: -1.5rem;
  --spacing-minus-32: -2rem;

  /* Navigation bar height */
  --nav-height: 45px;
}

@media screen and (min-width: 800px) {
  :root {
    --nav-height: 56px;
  }
}
