.clustered-collection__inner {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: var(--max-width);
  margin: auto;
  padding: 2rem 0;
}

@media screen and (min-width: 1280px) {
  .clustered-collection__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.clustered-collection__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.clustered-collection__copy,
.clustered-collection__title {
  margin: 0;
}

.clustered-collection__clusters {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.clustered-collection__cluster {
  display: grid;
  grid-template-areas:
    "title"
    "list"
    "button";
  gap: 0.5rem;
}

@media screen and (min-width: 1280px) {
  .clustered-collection__cluster {
    align-items: center;
    grid-template-areas:
      "title button"
      "list list";
  }
}

.clustered-collection__cluster-title {
  margin: 0;
  color: color-mix(in srgb, rgb(0, 0, 0) 60%, transparent);
  grid-area: title;
}

.clustered-collection__cluster-button-wrapper {
  grid-area: button;
  margin: auto;
}

@media screen and (min-width: 1280px) {
  .clustered-collection__cluster-button-wrapper {
    margin: unset;
    justify-self: flex-end;
  }
}

.clustered-collection__cluster-button {
  display: none;
}

.clustered-collection__cluster-button.active {
  display: block;
}

.clustered-collection__list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  list-style: none;
  grid-area: list;
  transition: max-height 0.5s ease-in-out;
}

.clustered-collection__list.minimized {
  max-height: 180px;
}

.clustered-collection__list.open {
  max-height: calc(60px * var(--items));
}

.clustered-collection__item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 0;
  border-top: 1px solid color-mix(in srgb, rgb(0, 0, 0) 20%, transparent);
  min-height: 60px;
}

.clustered-collection__item:first-child {
  border-top: none;
}

.clustered-collection__list.minimized .clustered-collection__item:nth-child(3) ~ .clustered-collection__item {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0, 1, 0, 1);
}

.clustered-collection__list.open .clustered-collection__item:nth-child(3) ~ .clustered-collection__item {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(1, 0, 1, 0);
}

.clustered-collection__item img,
.clustered-collection__item svg {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
}
