/* Neue Haas Grotesk */

@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url("/../fonts/NHaasGroteskTXPro55Rg/font.woff2") format("woff2"),
    url("/../fonts/NHaasGroteskTXPro55Rg/font.woff") format("woff");
}

@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    url("../fonts/NHaasGroteskTXPro56It/font.woff2") format("woff2"),
    url("../fonts/NHaasGroteskTXPro56It/font.woff") format("woff");
}

@font-face {
  font-family: "Neue Haas Grotesk";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url("../fonts/NHaasGroteskTXPro75Bd/font.woff2") format("woff2"),
    url("../fonts/NHaasGroteskTXPro75Bd/font.woff") format("woff");
}
