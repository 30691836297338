.logo-collection {
  position: relative;
  background-color: var(--off-white);
}

.logo-collection__inner {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5rem;
}

@media screen and (min-width: 800px) {
  .logo-collection__inner {
    padding-top: 2rem;
  }
}

.logo-collection--light {
  background-color: var(--off-white);
  color: var(--charcoal--darker);
}

.logo-collection--blue {
  background-color: var(--blue--lighter);
  color: var(--charcoal--darker);
}

.logo-collection--dark {
  background-color: var(--off-white);
  color: var(--charcoal);
}

.logo-collection__header {
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 1280px) {
  .logo-collection__header {
    grid-column: 1 / -1;
  }
}

.logo-collection__kicker {
  display: block;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

@media screen and (min-width: 800px) {
  .logo-collection__kicker {
    margin-bottom: 0.5rem;
  }
}

.logo-collection__headline {
  margin: 0;
}

.logo-collection__logos {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
}

@media screen and (min-width: 1280px) {
  .logo-collection__logos {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-column-gap: var(--grid-gap);
  }
}

.logo-collection__additional-logos {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
}

.logo-collection__logo {
  position: relative;
  height: auto;
  grid-column: span 3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: var(--corner-radius-l);
}

@media screen and (min-width: 800px) {
  .logo-collection__logo {
    grid-column: span 2;
    margin-bottom: 2rem;
  }
}

.logo-collection__logo-wrapper {
  width: 100%;
  max-width: 180px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.logo-collection__logo-wrapper svg {
  width: 100%;
  height: auto;
  display: block;
}

.logo-collection__logo-asset {
  width: 100%;
  height: auto;
  display: block;
}

.logo-collection__corner-top-left {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
  display: block;
  transform: rotate(-90deg);
}

.logo-collection__corner-bottom-right {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 0;
  bottom: 0;
  display: block;
  transform: rotate(90deg);
}

@media screen and (max-width: 799px) {
  .logo-collection__logo:nth-child(2) .logo-collection__corner-top-left {
    left: auto;
    right: 0;
    transform: rotate(0);
  }

  .logo-collection__logo:nth-child(2) .logo-collection__corner-bottom-right {
    left: 0;
    right: auto;
    transform: rotate(180deg);
  }

  .logo-collection__logo:nth-child(3) .logo-collection__corner-top-left {
    left: auto;
    right: 0;
    transform: rotate(0);
  }

  .logo-collection__logo:nth-child(3) .logo-collection__corner-bottom-right {
    left: 0;
    right: auto;
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 800px) {
  .logo-collection__logo:nth-child(even) .logo-collection__corner-top-left {
    left: auto;
    right: 0;
    transform: rotate(0);
  }

  .logo-collection__logo:nth-child(even) .logo-collection__corner-bottom-right {
    left: 0;
    right: auto;
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 1280px) {
  .logo-collection__logo:first-child:nth-last-child(2) {
    grid-column: span 4;
  }

  .logo-collection__logo:first-child:nth-last-child(3) {
    grid-column: span 2;
  }

  .logo-collection__logo:first-child:nth-last-child(2) ~ .logo-collection__logo {
    grid-column: span 4;
  }

  .logo-collection__logo:first-child:nth-last-child(2) .logo-collection__logo-asset {
    width: 50%;
  }

  .logo-collection__logo:first-child:nth-last-child(2) ~ .logo-collection__logo .logo-collection__logo-asset {
    width: 50%;
  }

  .logo-collection__logo:first-child:nth-last-child(3) ~ .logo-collection__logo {
    grid-column: span 2;
  }
}
