/* Previous component had colour theme, CSS vars are an echo of that */
.list-complex {
  --_background: var(--off-white);
  --_color: var(--charcoal--darker);
  --_link-color: var(--blue);
  --_link-color-hover: var(--blue--lighter);

  background-color: var(--_background);
  color: var(--_color);
}

/* csslint-disable-next-line */
.list-complex a {
  color: var(--_link-color);
}

/* csslint-disable-next-line */
.list-complex a:hover {
  color: var(--_link-color-hover);
}

.list-complex__inner {
  max-width: var(--max-width);
  margin-inline: auto;
  padding-block: 3rem;
}

@media screen and (min-width: 640px) {
  .list-complex__inner {
    padding-block: 4rem;
  }
}

.list-complex__headline {
  margin-block: 0; /* reset default */
}

.list-complex__content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
}

@media screen and (min-width: 640px) {
  .list-complex__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.list-complex__list-item {
  display: grid;
  grid-template-columns: auto 1fr;
  flex-direction: column;
  padding-block: var(--spacing-24);
  row-gap: var(--spacing-12);
  column-gap: var(--spacing-12);
}

@media screen and (min-width: 640px) {
  .list-complex__list-item {
    column-gap: var(--spacing-24);
  }
}

/* Remove top/bottom padding on the list */
.list-complex__list-item:first-child {
  padding-top: 0;
}

.list-complex__list-item:last-child {
  padding-bottom: 0;
}

.list-complex__list-item + .list-complex__list-item {
  border-top: 1px solid var(--color-divider);
}

.list-complex__image {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 640px) {
  .list-complex__image {
    grid-row: span 2;
    align-items: flex-start;
  }
}

.list-complex__image img {
  width: 48px;
  height: auto;
  display: block;
}

@media screen and (min-width: 640px) {
  .list-complex__image img {
    width: 80px;
  }
}

@media screen and (min-width: 640px) {
  .list-complex__image img {
    margin: var(--spacing-24);
  }
}

.list-complex__item-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-complex__item-kicker {
  text-transform: uppercase;
  margin-block: 0;
}

.list-complex__item-kicker + * {
  margin-top: var(--spacing-4);
}

.list-complex__item-title {
  margin-block: 0; /* override default margin */
}

.list-complex__item-text {
  grid-column: 1 / -1;
}

.list-complex__item-text > *:first-child {
  margin-top: 0;
}

.list-complex__item-text > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 640px) {
  .list-complex__item-text {
    grid-column: 2 / -1;
  }
}

.list-complex__list {
  width: 100%;
}

.list-complex__button-wrapper {
  flex-shrink: 0;
  margin-top: var(--spacing-24);
}
