.tile-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

@media screen and (min-width: 800px) {
  .tile-item--horizontal {
    flex-direction: row;
    gap: 2rem;
  }

  .tile-item--horizontal .tile-item__media {
    flex: 0 0 50%;
  }
}

.tile-item__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  justify-content: space-between;
}

.tile-item--horizontal .tile-item__content {
  justify-content: flex-start;
}

.tile-item__headline {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.tile-item__details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
