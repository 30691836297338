.link {
  color: var(--blue);
  text-decoration: none;
  padding: 0.2em;
  display: inline-flex;
  align-items: center;
}

.link:hover {
  color: var(--blue--lighter);
}

.link:focus {
  color: var(--off-white);
  background-color: var(--blue);
  border-radius: 4px;
}

.link:active {
  color: var(--blue--darker);
  background-color: unset;
}

.link:disabled {
  color: var(--supporting--disabled);
}

.link--dark {
  color: var(--blue--lighter);
}

.link.link--dark:hover {
  color: var(--blue);
}

.link.link--dark:focus {
  color: var(--charcoal);
  background-color: var(--blue--lighter);
}

.link.link--dark:active {
  color: var(--blue--darker);
  background-color: unset;
}

.link.link--dark:disabled {
  color: var(--supporting--disabled-inverted);
}

.link--blue {
  color: var(--charcoal--darker);
}

.link.link--blue:hover {
  color: var(--off-white);
}

.link.link--blue:focus {
  color: var(--blue--lighter);
  background-color: var(--charcoal--darker);
}

.link.link--blue:active {
  color: var(--charcoal);
  background-color: unset;
}

.link.link--blue:disabled {
  color: var(--supporting--disabled);
}

.link__icon {
  position: relative;
  width: 1em;
  height: 1em;
  margin-right: 0.25rem;
}
