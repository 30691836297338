body {
  font-family: sans-serif;
  font-size: 16px;
  color: var(--charcoal);
  background-color: var(--color-background-100);
  transition: padding-top 0.3s ease;
}

section {
  padding-left: var(--grid-horizontal-padding);
  padding-right: var(--grid-horizontal-padding);
}

img {
  max-width: 100%;
}

a,
a:link {
  text-decoration: none;
}

.no-scroll {
  overflow: hidden;
}
