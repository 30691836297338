.article-preview {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 0;
  border-top: 1px solid color-mix(in srgb, var(--charcoal--darker) 10%, transparent);
}

.article-preview--pinned {
  padding-top: 0;
  border-top: none;
}

@media screen and (min-width: 800px) {
  .article-preview:first-child {
    padding-top: 0;
    border-top: none;
  }
}

@media screen and (min-width: 1280px) {
  .article-preview {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: var(--grid-columns);
  }

  .article-preview--pinned {
    display: flex;
    align-self: flex-start;
    position: sticky;
    top: 2rem;
  }
}

@media screen and (min-width: 800px) {
  .article-preview__image {
    grid-column: span 4;
  }
}

.article-preview__article {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (min-width: 800px) {
  .article-preview__article {
    grid-column: span 8;
    justify-content: space-around;
  }
}

.article-preview__tags {
  margin-bottom: 1rem;
}

.article-preview__title {
  margin: 0;
}

@media screen and (min-width: 1280px) {
  .article-preview__details {
    order: -1;
  }

  .article-preview--pinned .article-preview__details {
    order: 0;
  }
}
