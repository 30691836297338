.article {
  position: relative;
  padding: var(--spacing-32) var(--grid-horizontal-padding);
}

.article__inner {
  position: relative;
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--spacing-24);
  grid-template-columns: var(--grid-columns);
  margin: 0 auto;
  max-width: var(--max-width);
}

.article__featured-image {
  grid-column: 1 / -1;
}

@media screen and (min-width: 800px) {
  .article__featured-image {
    grid-column: 1 / span 4;
    position: sticky;
    top: var(--spacing-32);
    transition: top 0.2s ease-in-out;
  }

  body[data-nav-open="true"] .article__featured-image {
    top: calc(var(--nav-height) + var(--spacing-32));
  }
}

@media screen and (min-width: 1280px) {
  .article__featured-image {
    grid-column: 1 / span 6;
  }
}

.article__masthead {
  grid-column: 1 / -1;
}

@media screen and (min-width: 800px) {
  .article__masthead {
    grid-column: 5 / -1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1280px) {
  .article__masthead {
    grid-column: 7 / -1;
  }
}

.article__tags {
  flex-grow: 1;
  margin: var(--spacing-16) 0;
}

.article__title {
  margin: 0;
}

.article__title--leftaligned {
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  .article__title--leftaligned {
    grid-column: 1 / span 4;
    position: sticky;
    top: var(--spacing-32);
  }
}

@media screen and (min-width: 1280px) {
  .article__title--leftaligned {
    grid-column: 1 / span 6;
  }
}

.article__intro {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
  grid-column: 1 / -1;
}

@media screen and (min-width: 800px) {
  .article__intro {
    grid-column: 5 / -1;
  }
}

@media screen and (min-width: 1280px) {
  .article__intro {
    grid-column: 7 / -1;
  }
}

.article__author {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
}

.article__author-image .media img {
  border-radius: 50%;
  display: block;
  height: 40px;
  width: 40px;
}

.article__author-image--larger .media img {
  height: 64px;
  width: 64px;
}

.article__author-name {
  margin: 0;
}

.article__blocks {
  padding: var(--spacing-32) 0;
  border-top: 1px solid var(--color-black-20);
  border-bottom: 1px solid var(--color-black-20);
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-48);
}

@media screen and (min-width: 800px) {
  .article__blocks {
    grid-column: 5 / -1;
  }
}

@media screen and (min-width: 1280px) {
  .article__blocks {
    grid-column: 7 / -1;
  }
}

.article__blocks > * {
  padding-left: 0;
  padding-right: 0;
  display: block !important;
}

.article__blocks--page {
  border-top: none;
  border-bottom: none;
  padding-top: 0;
}

.article__footer {
  grid-column: 1 / -1;
  padding-top: var(--spacing-8);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
}

@media screen and (min-width: 800px) {
  .article__footer {
    grid-column: 5 / -1;
    padding-top: var(--spacing-24);
  }
}

@media screen and (min-width: 1280px) {
  .article__footer {
    grid-column: 7 / -1;
  }
}

.article__footer .article__tags {
  margin: 0;
}

.article__bio {
  background-color: var(--off-white--darker);
  border-radius: var(--corner-radius-l);
  padding: 1.5rem 1.25rem;
}

.article__footer-label {
  display: block;
  color: var(--color-black-60);
  margin-bottom: var(--spacing-12);
}

.article__meta {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  grid-column: 1 / -1;
  padding-bottom: var(--spacing-32);
  border-bottom: 1px solid var(--color-black-20);
}

@media screen and (min-width: 800px) {
  .article__meta {
    grid-column: 5 / -1;
  }
}

@media screen and (min-width: 1280px) {
  .article__meta {
    grid-column: 7 / -1;
  }
}

/* TOC */

.article__toc {
  width: 100%;
}

.article__toc h4 {
  font-size: 1.2em;
  margin-bottom: 1em;
}

.toc__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  counter-reset: item;
}

.toc__item {
  display: flex;
  margin: 0.75em 0;
  padding-left: 3rem;
  position: relative;
  color: var(--color-black-80);
  align-items: center;
}

.toc__item::before {
  content: counter(item);
  counter-increment: item;
  background-color: #e0e0e0;
  border-radius: var(--corner-radius-s);
  display: inline-block;
  font-size: var(--text-preset-small__font-size);
  text-align: center;
  line-height: 2em;
  color: var(--color-black-80);
  position: absolute;
  left: 0;
  min-width: 44px;
}

.toc__item.toc__item--h3 {
  margin-left: 3rem;
}

.toc__item.toc__item--h3::before {
  content: counter(item) "." counter(subitem);
  counter-increment: subitem;
}

.toc__item.toc__item--h2 {
  counter-reset: subitem;
}

.toc__link {
  text-decoration: none;
  color: #333;
  font-weight: normal;
  margin-left: 0.5em;
}

.toc__link:hover {
  text-decoration: underline;
}
