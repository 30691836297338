.text-cta {
  position: relative;
}

.text-cta__inner {
  max-width: var(--max-width);
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-column-gap: var(--grid-gap);
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media screen and (min-width: 800px) {
  .text-cta__inner {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

.text-cta--light {
  background-color: var(--off-white);
  color: var(--charcoal--darker);
}

.text-cta--light-alt {
  background-color: var(--off-white--darker);
  color: var(--charcoal--darker);
}

.text-cta--blue {
  background-color: var(--blue--lighter);
  color: var(--charcoal--darker);
}

.text-cta--dark {
  background-color: var(--charcoal);
  color: var(--off-white);
}

.text-cta__header {
  align-items: flex-end;
  display: flex;
  grid-column: 1 / -1;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.text-cta__kicker {
  display: block;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}

@media screen and (min-width: 800px) {
  .text-cta__kicker {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}

.text-cta__headline {
  margin: 0;
  padding: 0;
}

.text-cta__body {
  margin: 2rem 0 0 0;
  padding: 0;
}

.text-cta__arrows {
  display: none;
}

@media screen and (min-width: 800px) {
  .text-cta__arrows {
    display: flex;
    cursor: pointer;
  }

  .text-cta__arrow {
    width: 3.25rem;
    height: 3.25rem;
  }
}

.text-cta__items {
  scroll-snap-type: x mandatory;
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  width: 100vw;
  transform: translateX(calc(var(--grid-horizontal-padding) * -1));
}

@media screen and (min-width: 800px) {
  .text-cta__items {
    -webkit-overflow-scrolling: unset;
    overflow-x: unset;
    width: unset;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: var(--grid-columns);
    grid-column-gap: var(--grid-gap);
    transform: unset;
  }
}

.text-cta__items::-webkit-scrollbar-track,
.text-cta__items::-webkit-scrollbar,
.text-cta__items::-webkit-scrollbar-thumb {
  display: none;
}

.text-cta__item {
  min-width: calc((100vw - 2 * var(--grid-horizontal-padding)) * 0.9);
  padding-left: var(--grid-horizontal-padding);
  padding-right: 20px;
  scroll-snap-align: start;
}

@media screen and (min-width: 800px) {
  .text-cta__item {
    min-width: unset;
    padding-right: unset;
    padding-left: unset;
    grid-column: span 4;
    margin-bottom: -1rem;
  }
}

@media screen and (min-width: 1280px) {
  .text-cta__item {
    grid-column: span 6;
  }

  /* two items */
  .text-cta__item:first-child:nth-last-child(2),
  .text-cta__item:first-child:nth-last-child(2) ~ .text-cta__item {
    grid-column: span 6;
  }

  /* three items */
  /* stylelint-disable no-descending-specificity */
  .text-cta__item:first-child:nth-last-child(n + 3),
  .text-cta__item:first-child:nth-last-child(n + 3) ~ .text-cta__item {
    grid-column: span 4;
  }
  /* stylelint-enable no-descending-specificity */
}

@media screen and (min-width: 800px) {
  .text-cta__item--hidden {
    display: none;
  }
}

.text-cta__item-headline {
  border-top: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 800px) {
  .text-cta__item-headline {
    padding-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

.text-cta__item-headline--no-image {
  border-top: unset;
  border-bottom: 1px solid currentcolor;
  padding-top: 0;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.text-cta__item-text {
  margin-bottom: 1rem;
}

.text-cta__item-media {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 800px) {
  .text-cta__item-media {
    margin-bottom: 1.75rem;
  }
}

.text-cta__item-links {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
}

/* csslint-disable-next-line */
.text-cta__item-links > * {
  margin-bottom: 0.5rem;
}

/* csslint-disable-next-line */
.text-cta__item-links > *:last-child {
  margin-bottom: 0;
}
