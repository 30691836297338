/* csslint-disable-next-line */
:root {
  --font-family-primary: "Neue Haas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;

  /* Hyperlinks */
  --link-color: inherit;
  --link-underline-thickness: max(0.0625rem, 0.07em);
  --link-underline-offset: 0.18em;
  --link-underline-color: var(--bacics-black-30);
  --link-underline-color-hover: currentColor;

  /* Font Weights */
  --font-weight-normal-regular: 400;
  --font-weight-bold: 700;

  /* Headline H1 */
  --text-preset-h1__font-family: var(--font-family-primary);
  --text-preset-h1__font-size: 2.5rem /* 40px */;
  --text-preset-h1__font-weight: var(--font-weight-normal-regular);
  --text-preset-h1__line-height: 3.325rem /* 53.3px */;
  --text-preset-h1__letter-spacing: 0;

  /* Headline H2 */
  --text-preset-h2__font-family: var(--font-family-primary);
  --text-preset-h2__font-size: 2rem /* 32px */;
  --text-preset-h2__font-weight: var(--font-weight-normal-regular);
  --text-preset-h2__line-height: 2.66rem /* 42.6px */;
  --text-preset-h2__letter-spacing: 0;

  /* Headline H3 */
  --text-preset-h3__font-family: var(--font-family-primary);
  --text-preset-h3__font-size: 1.5rem /* 24px */;
  --text-preset-h3__font-weight: var(--font-weight-normal-regular);
  --text-preset-h3__line-height: 2rem /* 32px */;
  --text-preset-h3__letter-spacing: 0;

  /* Headline H4 */
  --text-preset-h4__font-family: var(--font-family-primary);
  --text-preset-h4__font-size: 1.25rem /* 20px */;
  --text-preset-h4__font-weight: var(--font-weight-normal-regular);
  --text-preset-h4__line-height: 1.66rem /* 26.6px */;
  --text-preset-h4__letter-spacing: 0;

  /* Quote Large */
  --text-preset-quote-large__font-family: var(--font-family-primary);
  --text-preset-quote-large__font-size: 2rem /* 32px */;
  --text-preset-quote-large__font-weight: var(--font-weight-normal-regular);
  --text-preset-quote-large__line-height: 2.66rem /* 42.6px */;
  --text-preset-quote-large__letter-spacing: 0;

  /* Quote Medium */
  --text-preset-quote-medium__font-family: var(--font-family-primary);
  --text-preset-quote-medium__font-size: 1.25rem /* 20px */;
  --text-preset-quote-medium__font-weight: var(--font-weight-normal-regular);
  --text-preset-quote-medium__line-height: 1.66rem /* 26.6px */;
  --text-preset-quote-medium__letter-spacing: 0;

  /* Paragraph */
  --text-preset-paragraph__font-family: var(--font-family-primary);
  --text-preset-paragraph__font-size: 1rem /* 16px */;
  --text-preset-paragraph__font-weight: var(--font-weight-normal-regular);
  --text-preset-paragraph__line-height: 1.33rem /* 21.3px */;
  --text-preset-paragraph__letter-spacing: 0;

  /* Paragraph Trimmed */
  --text-preset-paragraph-trimmed__font-family: var(--font-family-primary);
  --text-preset-paragraph-trimmed__font-size: 1rem /* 16px */;
  --text-preset-paragraph-trimmed__font-weight: var(--font-weight-normal-regular);
  --text-preset-paragraph-trimmed__line-height: 1.33rem /* 21.3px */;
  --text-preset-paragraph-trimmed__letter-spacing: 0;

  /* Small Text */
  --text-preset-small__font-family: var(--font-family-primary);
  --text-preset-small__font-size: 0.875rem /* 14px */;
  --text-preset-small__font-weight: var(--font-weight-normal-regular);
  --text-preset-small__line-height: 1.16rem /* 18.6px */;
  --text-preset-small__letter-spacing: 0;

  /* Small Text Trimmed */
  --text-preset-small-trimmed__font-family: var(--font-family-primary);
  --text-preset-small-trimmed__font-size: 0.875rem /* 14px */;
  --text-preset-small-trimmed__font-weight: var(--font-weight-normal-regular);
  --text-preset-small-trimmed__line-height: 1.16rem /* 18.6px */;
  --text-preset-small-trimmed__letter-spacing: 0;
}

@media screen and (max-width: 50em) {
  /* 800px */

  :root {
    /* Headline H1 */
    --text-preset-h1__font-size: 1.75rem /* 28px */;
    --text-preset-h1__line-height: 2.32rem /* 37.2px */;

    /* Headline H2 */
    --text-preset-h2__font-size: 1.5rem /* 24px */;
    --text-preset-h2__line-height: 2rem /* 32px */;

    /* Headline H3 */
    --text-preset-h3__font-size: 1.25rem /* 20px */;
    --text-preset-h3__line-height: 1.66rem /* 26.6px */;

    /* Headline H4 */
    --text-preset-h4__font-size: 1.125rem /* 18px */;
    --text-preset-h4__line-height: 1.5rem /* 24px */;

    /* Quote Large */
    --text-preset-quote-large__font-size: 1.5rem /* 24px */;
    --text-preset-quote-large__line-height: 2rem /* 32px */;
  }
}

@media screen and (min-width: 64em) {
  /* 1024px */

  :root {
    /* Headline H2 */
    --text-preset-h2__line-height: 1.33 /* 42.6px */;
  }
}

/* csslint-disable-next-line */
body {
  -moz-font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* csslint-disable-next-line */
body,
.text-preset-paragraph {
  font-family: var(--text-preset-paragraph__font-family);
  font-size: var(--text-preset-paragraph__font-size);
  font-weight: var(--text-preset-paragraph__font-weight);
  line-height: var(--text-preset-paragraph__line-height);
  letter-spacing: var(--text-preset-paragraph__letter-spacing);
}

/* csslint-disable-next-line */
a {
  color: var(--link-color);
  text-decoration: underline;
  text-underline-offset: var(--link-underline-offset);
  text-decoration-thickness: var(--link-underline-thickness);
  text-decoration-color: var(--link-underline-color);
  transition: text-decoration-color var(--link-transition);
}

/* csslint-disable-next-line */
a:hover {
  --link-underline-color: var(--link-underline-color-hover);
}

/* csslint-disable-next-line */
.text-preset-h1 {
  font-family: var(--text-preset-h1__font-family);
  font-size: var(--text-preset-h1__font-size);
  font-weight: var(--text-preset-h1__font-weight);
  line-height: var(--text-preset-h1__line-height);
  letter-spacing: var(--text-preset-h1__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset-h2 {
  font-family: var(--text-preset-h2__font-family);
  font-size: var(--text-preset-h2__font-size);
  font-weight: var(--text-preset-h2__font-weight);
  line-height: var(--text-preset-h2__line-height);
  letter-spacing: var(--text-preset-h2__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset-h3 {
  font-family: var(--text-preset-h3__font-family);
  font-size: var(--text-preset-h3__font-size);
  font-weight: var(--text-preset-h3__font-weight);
  line-height: var(--text-preset-h3__line-height);
  letter-spacing: var(--text-preset-h3__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset-h4 {
  font-family: var(--text-preset-h4__font-family);
  font-size: var(--text-preset-h4__font-size);
  font-weight: var(--text-preset-h4__font-weight);
  line-height: var(--text-preset-h4__line-height);
  letter-spacing: var(--text-preset-h4__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset-quote-large {
  font-family: var(--text-preset-quote-large__font-family);
  font-size: var(--text-preset-quote-large__font-size);
  font-weight: var(--text-preset-quote-large__font-weight);
  line-height: var(--text-preset-quote-large__line-height);
  letter-spacing: var(--text-preset-quote-large__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset-quote-medium {
  font-family: var(--text-preset-quote-medium__font-family);
  font-size: var(--text-preset-quote-medium__font-size);
  font-weight: var(--text-preset-quote-medium__font-weight);
  line-height: var(--text-preset-quote-medium__line-height);
  letter-spacing: var(--text-preset-quote-medium__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset-paragraph-trimmed {
  font-family: var(--text-preset-paragraph-trimmed__font-family);
  font-size: var(--text-preset-paragraph-trimmed__font-size);
  font-weight: var(--text-preset-paragraph-trimmed__font-weight);
  line-height: var(--text-preset-paragraph-trimmed__line-height);
  letter-spacing: var(--text-preset-paragraph-trimmed__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset-small {
  font-family: var(--text-preset-small__font-family);
  font-size: var(--text-preset-small__font-size);
  font-weight: var(--text-preset-small__font-weight);
  line-height: var(--text-preset-small__line-height);
  letter-spacing: var(--text-preset-small__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset-small-trimmed {
  font-family: var(--text-preset-small-trimmed__font-family);
  font-size: var(--text-preset-small-trimmed__font-size);
  font-weight: var(--text-preset-small-trimmed__font-weight);
  line-height: var(--text-preset-small-trimmed__line-height);
  letter-spacing: var(--text-preset-small-trimmed__letter-spacing);
}

/* csslint-disable-next-line */
.text-preset--upright {
  font-style: normal;
}

/* csslint-disable-next-line */
.text-preset--italic {
  font-style: italic;
}

/* csslint-disable-next-line */
.text-preset--regular {
  font-weight: var(--font-weight-normal-regular);
}

/* csslint-disable-next-line */
.text-preset--bold {
  font-weight: var(--font-weight-bold);
}
