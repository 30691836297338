.faq__inner {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: var(--max-width);
  margin: auto;
  padding: 2rem 0;
}

@media screen and (min-width: 1280px) {
  .faq__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.faq__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq__header h2,
.faq__header p {
  margin: 0;
}

.faq__list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 0;
  margin: 0;
}

.faq__list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq__item {
  list-style: none;
  padding-bottom: var(--spacing-24);
  margin-bottom: var(--spacing-24);
  border-bottom: 1px solid var(--color-black-10);
}

.faq__item:last-child {
  padding-bottom: var(--spacing-24);
  margin-bottom: 0;
  border-bottom: none;
}

@media screen and (min-width: 1280px) {
  .faq__item {
    padding-bottom: var(--spacing-32);
    margin-bottom: var(--spacing-32);
  }

  .faq__item:last-child {
    padding-bottom: var(--spacing-32);
  }
}

.faq__question {
  padding-right: 30px;
  margin-top: 0;
  margin-bottom: 0;
}

.faq__answer {
  display: grid;
  grid-template-rows: 0fr;
  transition:
    250ms grid-template-rows ease,
    500ms opacity ease;
  opacity: 0;
}

.faq__answer.expanded {
  grid-template-rows: 1fr;
  opacity: 1;
}

.faq__answer-content {
  overflow: hidden;
  margin: 0;
}

.faq__accordion-button--collapse {
  display: none;
}

.faq__accordion-button--expand {
  display: block;
}

.faq__item.expanded .faq__accordion-button--expand {
  display: none;
}

.faq__item.expanded .faq__accordion-button--collapse {
  display: block;
}

.faq__item.expanded .faq__answer {
  grid-template-rows: 1fr;
  opacity: 1;
}

.faq__link {
  display: block;
  margin-top: 1rem;
}

.faq ol {
  counter-reset: list-counter;
  list-style: none;
  padding-left: 0;
  margin: 14px 0;
}

.faq ol li {
  position: relative;
  margin: 0 0 14px 12px;
  padding-left: 38px;
}

.faq ol li::before {
  content: counter(list-counter);
  counter-increment: list-counter;
  position: absolute;
  left: 0;
  top: -1px;
  width: 24px;
  height: 24px;
  background-color: #d9d9da;
  color: #57585d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
