.article-collection {
  padding: 2rem var(--grid-horizontal-padding);
}

.article-collection__inner {
  margin: auto;
  max-width: var(--max-width);
  position: relative;
}

@media screen and (min-width: 800px) {
  .article-collection__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--grid-gap);
  }
}

.article-collection__headline {
  margin-top: 0;
  padding-top: 0;
}

.article-collection__pagination {
  display: flex;
  justify-content: space-between;
}

.article-collection__list--teaser-items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
}

.article-collection__list--teaser-items > .teaser-item {
  min-height: 108px;
}
